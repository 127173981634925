import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import MainTab from '../../components/main-tab/main-tab'
import { entryItemAction } from '../../reducers/entry-items-reducer/entry-items-reducer'
import EntryItemPage from '../../components/entry-item-page/entry-item-page'
import { entryItemSchemas } from '../../schemas/entry-item-schemas'
import { getFullEntryItems } from '../../utils/redux-dispatch-configurations'
import { useNavigate, useMatch } from 'react-router-dom'
import { returnToSameSubPageAfterRefresh, getEntryItemPageContent } from './entry-items-menu.functions'

const EntryItemsMenu = () => {
    const [active, setActive] = useState('asiakkaat')
    const { loading, fullEntryItems } = useSelector(state => state.entryItems)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const match = useMatch('/kirjaustiedot/:active')

    if (match && active !== match.params.active)
        returnToSameSubPageAfterRefresh(setActive, match.params.active)

    useEffect(() => {
        !fullEntryItems && dispatch(entryItemAction({}, getFullEntryItems))
    }, [dispatch, fullEntryItems])

    let tab

    const content = getEntryItemPageContent(active)

    if (content) {
        tab = <EntryItemPage
            data={fullEntryItems}
            inputFields={content.inputFields}
            key={content.id}
            notificationValue={content.notificationValue}
            tableColumns={content.tableColumns}
            tableItems={content.tableItems}
            title={content.title}
        />
    }

    return (
        <div>
            <div className='main-row'>
                {entryItemSchemas.map(item => <div
                    key={item.id}
                    onClick={(() => navigate(`${item.tabName}`))}>
                    <MainTab
                        active={active}
                        setActive={setActive}
                        tabName={item.tabName}
                        title={item.tabTitle} />
                </div>)}
            </div>
            <div className='main-content'>
                {!fullEntryItems || loading ?
                    <Loader
                        active={loading}
                        inline='centered' /> :
                    <>
                        {tab}
                    </>
                }
            </div>
        </div>
    )
}

export default EntryItemsMenu
