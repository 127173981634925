import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Loader } from 'semantic-ui-react'
import CustomerInput from '../../../components/customer-input/customer-input'
import { sortFromAndToDates, startOfDateMonthAgo, endOfCurrentDate } from '../../../utils/time'
import RadioButton from '../../../components/radio-button/radio-button'
import OrdersTable from 'src/components/orders-table/orders-table'
import AmountOrDateRangeValue from 'src/components/amount-or-date-range-value/amount-or-date-range-value'
import {
    handleAmountChange,
    handleSortOrderChange,
    handleDisplayChange,
    getWebView,
    keyPressHandler,
    handleDateChange,
    getMoreOrders,
    setNewLimitStart,
} from './webview-report.functions'

const limit = 50
const typeText = 'Tyyppi'
const typeTextLowerCase = typeText.toLowerCase()
const customerText = 'Asiakas'
const customerTextLowerCase = customerText.toLowerCase()
const worksiteText = 'Työmaa'
const worksiteTextLowerCase = worksiteText.toLowerCase()
const entryUserText = 'Kirjaaja'
const entryUserTextLowerCase = entryUserText.toLowerCase()
const deliveryText = 'Toimitus'
const deliveryDateText = 'toimituspäivä'
const optionAmount = 'optionAmount'
const euro = '€'
const desc = 'DESC'
const asc = 'ASC'
const resizeString = 'resize'
const initialWindowSize = { windowWidth: 0, windowHeight: 0 }

const getTableColumns = ({ windowWidth, windowHeight }) => {
    if (window.screen.orientation.angle === 90 && window.screen.orientation.type === 'landscape-primary' && windowWidth > windowHeight) {
        return [
            { header: euro, hideInMobile: false },
            { header: typeText, valueName: typeTextLowerCase, hideInMobile: false },
            { header: customerText, valueName: customerTextLowerCase, hideInMobile: false },
            { header: worksiteText, valueName: worksiteTextLowerCase, hideInMobile: true },
            { header: entryUserText, valueName: entryUserTextLowerCase, hideInMobile: true },
            { header: deliveryText, valueName: deliveryDateText, hideInMobile: false },
        ]
    }

    return [
        { header: euro, hideInMobile: true },
        { header: typeText, valueName: typeTextLowerCase, hideInMobile: false },
        { header: customerText, valueName: customerTextLowerCase, hideInMobile: false },
        { header: worksiteText, valueName: worksiteTextLowerCase, hideInMobile: true },
        { header: entryUserText, valueName: entryUserTextLowerCase, hideInMobile: true },
        { header: deliveryText, valueName: deliveryDateText, hideInMobile: true },
    ]
}

const WebviewReport = () => {
    const [selectDisplay, setSelectDisplay] = useState(optionAmount)
    const dispatch = useDispatch()
    const [tableIsShown, setTableIsShown] = useState(false)
    const [fromDate, setFromDate] = useState(startOfDateMonthAgo)
    const [toDate, setToDate] = useState(endOfCurrentDate)
    const [reportAmount, setReportAmount] = useState(25)
    const [limitStart, setLimitStart] = useState(0)
    const [sortOrder, setSortOrder] = useState(desc)
    const customerForm = useRef(null)
    const [windowSize, setWindowSize] = useState(initialWindowSize)
    const { report, reportIsLoading, infiniteScrollingIsLoading, statusCode } = useSelector(state => state.report)
    const tableColumns = getTableColumns(windowSize)
    const { firstDate, secondDate } = sortFromAndToDates(fromDate, toDate)
    const sortOrderArray = [{ title: 'Laskeva', value: desc, key: 1 }, { title: 'Nouseva', value: asc, key: 2 }]
    const displayArray = [{ title: 'Määrä', value: optionAmount, key: 1 }, { title: 'Aikaväli', value: 'optionTime', key: 2 }]
    const resizeWindow = () => setWindowSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight })

    let tableItems = []

    if (report.length > 0 && statusCode === 200)
        tableItems = report

    useEffect(() => {
        resizeWindow()
        window.addEventListener(resizeString, resizeWindow)

        return () => window.removeEventListener(resizeString, resizeWindow)
    }, [])

    return (
        <>
            <div className='reports-description'>
                <h2>Näyttöraportti</h2>
                <div>
                    Näyttöraportti hakee kirjaukset valittujen tietojen perusteella ja listaa ne sivun alalaitaan.
                </div>
            </div>
            <div className='reports-container'>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Rajaus:</h4>
                    </div>
                    <RadioButton
                        handleValueChange={handleDisplayChange(setSelectDisplay, setTableIsShown)}
                        value={selectDisplay}
                        valueArray={displayArray}
                    />
                </div>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Järjestys:</h4>
                    </div>
                    <RadioButton
                        handleValueChange={handleSortOrderChange(setSortOrder)}
                        value={sortOrder}
                        valueArray={sortOrderArray}
                    />
                </div>
                <AmountOrDateRangeValue
                    amountOptions={{ handleAmountChange: handleAmountChange(setReportAmount), reportAmount: reportAmount }}
                    dateIntervalOptions={[fromDate, handleDateChange(setFromDate), toDate, handleDateChange(setToDate)]}
                    selectDisplay={selectDisplay}
                />
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Asiakas:</h4>
                    </div>
                    <div className='reports-selector-box ui input '>
                        <form ref={customerForm}>
                            <CustomerInput
                                keyPressHandler={keyPressHandler(getWebView({
                                    customerForm,
                                    selectDisplay,
                                    reportIsLoading,
                                    dispatch,
                                    sortOrder,
                                    reportAmount,
                                    limit,
                                    firstDate,
                                    secondDate,
                                    setLimitStart,
                                    setTableIsShown,
                                }))}
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div className='selector-box-dl-btn'>
                <Button
                    className='orange-white-btn'
                    content='Hae tiedot'
                    icon='download'
                    onClick={getWebView({
                        customerForm,
                        selectDisplay,
                        reportIsLoading,
                        dispatch,
                        sortOrder,
                        reportAmount,
                        limit,
                        firstDate,
                        secondDate,
                        setLimitStart,
                        setTableIsShown,
                    })}
                />
            </div>
            <OrdersTable
                report={report}
                reportIsLoading={reportIsLoading}
                tableIsShown={tableIsShown}
                tableWithModalConfig={{
                    tableItems: tableItems,
                    tableColumns: tableColumns,
                    selectDisplay: selectDisplay,
                    getMoreOrders: getMoreOrders({
                        infiniteScrollingIsLoading,
                        dispatch,
                        sortOrder,
                        limitStart,
                        limit,
                        firstDate,
                        secondDate,
                        setLimitStart,
                    }),
                    reportAmount: reportAmount,
                    limit: limit,
                    limitStart: limitStart,
                    sortOrder: sortOrder,
                    setNewLimitStart: setNewLimitStart(setLimitStart, limitStart),
                }}
            />
            <Loader
                active={infiniteScrollingIsLoading}
                className='content-loader'
                inline='centered' />
        </>
    )
}

export default WebviewReport
