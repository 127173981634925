import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { logout } from '../../utils/redux-dispatch-configurations'
import { errorAction, expiredTokenAction } from '../../reducers/notification-reducer/notification-reducer'
import { loginAction } from 'src/reducers/login-reducer/login-reducer'
import { addLeftPadding, nullish } from '../../utils/helper-functions'
import { convertToLocalDate } from 'src/utils/time'

const getValueOrAlternative = (value, alternative) => value || alternative
const getValueOrUndefined = value => getValueOrAlternative(value, undefined)
const getIdOrTextOrUndefined = (itemId, itemText) => !itemId ? getValueOrUndefined(itemText) : undefined
const getValueOrEmpty = value => getValueOrAlternative(value, '')
const customer = 'customer'
const worksite = 'worksite'
const worker = 'worker'
const vehicle = 'vehicle'
const accessory = 'accessory'
const pickup = 'pickup'
const load = 'load'
const product = 'product'
const quantity = 'quantity'
const comment = 'comment'
const file = 'file'
const routeLength = 'route_length'
const deliveryDate = 'delivery_date'
const zeroTime = '00:00:00'

export const handleOrderAttachmentIds = setOrderAttachmentIds => orderAttachmentIdArray => {
    setOrderAttachmentIds(orderAttachmentIdArray)
}

export const calculateDuration = (startTimeHours, endTimeHours, startTimeMinutes, endTimeMinutes, setDurationHours, setDurationMinutes) => {
    const totalEndMinutes = endTimeHours * 60 + endTimeMinutes
    const totalStartMinutes = startTimeHours * 60 + startTimeMinutes

    if (totalEndMinutes < totalStartMinutes || (totalEndMinutes - totalStartMinutes > 23 * 60)) {
        setDurationHours(0)
        setDurationMinutes(0)

        return
    }

    setDurationHours(Math.floor((totalEndMinutes - totalStartMinutes) / 60))
    setDurationMinutes((totalEndMinutes - totalStartMinutes) % 60)
}

export const valueUp = setIsInitialEditing => (name, condition, resetAmount, addAmount, newValue, setNewValue) => {
    if (newValue >= condition)
        setNewValue(resetAmount)
    else if (newValue % addAmount !== 0)
        setNewValue((Math.floor(newValue / addAmount) + 1) * addAmount)
    else
        setNewValue(newValue + addAmount)

    name !== 'duration' && setIsInitialEditing(false)
}

export const valueDown = setIsInitialEditing => (name, condition, resetAmount, subtractAmount, newValue, setNewValue) => {
    if (newValue <= condition)
        setNewValue(resetAmount)
    else if (newValue % subtractAmount !== 0)
        setNewValue(Math.floor(newValue / subtractAmount) * subtractAmount)
    else
        setNewValue(newValue - subtractAmount)

    name !== 'duration' && setIsInitialEditing(false)
}

export const whichTimeSelector = (
    name,
    {
        startHours,
        setStartHours,
        startMinutes,
        setStartMinutes,
        endHours,
        setEndHours,
        endMinutes,
        setEndMinutes,
    }
) => {
    if (name === 'start_time') {
        return {
            hours: startHours,
            setHours: setStartHours,
            minutes: startMinutes,
            setMinutes: setStartMinutes,
        }
    }

    return {
        hours: endHours,
        setHours: setEndHours,
        minutes: endMinutes,
        setMinutes: setEndMinutes,
    }
}

export const editOrders = ({
    urlLocation,
    setOrderId,
    setIsEditing,
    setIsInitialEditing,
    setDurationMinutes,
    setDurationHours,
    setStartMinutes,
    setStartHours,
    setEndMinutes,
    setEndHours,
    setUserInfo,
    setId,
    setGeolocation,
    setOrderAssignment,
    setValues,
}) => {
    const { edit, item, oa } = urlLocation.state
    const duration = nullish(item.kesto, zeroTime)
    const startTime = nullish(item.aloitus, zeroTime)
    const endTime = nullish(item.lopetus, zeroTime)

    setOrderId(item.tilausnro)
    setIsEditing(edit)
    setIsInitialEditing(true)

    setDurationMinutes(Number(duration.slice(3, 5)))
    setDurationHours(Number(duration.slice(0, 2)))
    setStartMinutes(Number(startTime.slice(3, 5)))
    setStartHours(Number(startTime.slice(0, 2)))
    setEndMinutes(Number(endTime.slice(3, 5)))
    setEndHours(Number(endTime.slice(0, 2)))

    const editValue = {
        [customer]: { text: getValueOrEmpty(item.asiakas), id: item.customer_id, group_id: item.customergroup_id },
        [worksite]: { text: getValueOrEmpty(item['työmaa']), id: item.worksite_id },
        [worker]: { text: getValueOrEmpty(item['työntekijä']), id: item.worker_id },
        [vehicle]: { text: getValueOrEmpty(item.ajoneuvo), id: item.vehicle_id },
        [accessory]: { text: getValueOrEmpty(item['lisävaruste']), id: item.accessory_id },
        [pickup]: { text: getValueOrEmpty(item.noutopaikka), id: item.pickup_id },
        [load]: { text: getValueOrEmpty(item.kuormalajike), id: item.load_id },
        [product]: { text: getValueOrEmpty(item.tarvike), id: item.product_id, group_id: item.productgroup_id },
        [quantity]: { text: getValueOrEmpty(item['määrä']), unit_id: item.unit_id, unit_text: item['yksikkö'] },
        [comment]: { text: getValueOrEmpty(item.kommentti) },
        [file]: { text: getValueOrEmpty(item.liite) },
        [routeLength]: { text: getValueOrEmpty(item.kuljetusmatka), unit_text: item['matkayksikkö'] },
        [deliveryDate]: { text: item['toimituspäivä'] },
    }

    if (edit) {
        setUserInfo({ user_name: item.kirjaaja, id: item.user_id })
        setId(item.tilausnro)
        setGeolocation(item.sijainti)
    }

    setOrderAssignment(oa)
    setValues(editValue)
}

export const saveRecord = (
    jobWillBeConvertedToOrder,
    typeId,
    handleOrderButtonAction,
    turnJobToOrder,
    {
        isEditing,
        setIsLoading,
        id,
        userInfo,
        orderAssignment,
        geolocation,
        navigate,
        values,
        dispatch,
        durationHours,
        durationMinutes,
        startHours,
        startMinutes,
        endHours,
        endMinutes,
        orderAttachmentIds,
    }
) => async () => {
    setIsLoading(true)

    const apiEndPoint = isEditing ? awsApiEndpoints.editOrder : awsApiEndpoints.createOrder

    const saveBody = {
        ownJobToOrder: jobWillBeConvertedToOrder,
        orderassignment: orderAssignment,
        ...isEditing && { id: id },
        type_id: typeId,
        user_id: userInfo.id,
        customergroup_id: getValueOrUndefined(values[customer].group_id),
        customer_id: getValueOrUndefined(values[customer].id),
        customer_text: getIdOrTextOrUndefined(values[customer].id, values[customer].text),
        worksite_id: getValueOrUndefined(values[worksite].id),
        worksite_text: getIdOrTextOrUndefined(values[worksite].id, values[worksite].text),
        worker_id: getValueOrUndefined(values[worker].id),
        worker_text: getIdOrTextOrUndefined(values[worker].id, values[worker].text),
        vehicle_id: getValueOrUndefined(values[vehicle].id),
        vehicle_text: getIdOrTextOrUndefined(values[vehicle].id, values[vehicle].text),
        accessory_id: getValueOrUndefined(values[accessory].id),
        accessory_text: getIdOrTextOrUndefined(values[accessory].id, values[accessory].text),
        pickup_id: getValueOrUndefined(values[pickup].id),
        pickup_text: getIdOrTextOrUndefined(values[pickup].id, values[pickup].text),
        load_id: getValueOrUndefined(values[load].id),
        load_text: getIdOrTextOrUndefined(values[load].id, values[load].text),
        productgroup_id: getValueOrUndefined(values[product].group_id),
        product_id: getValueOrUndefined(values[product].id),
        product_text: getIdOrTextOrUndefined(values[product].id, values[product].text),
        quantity: getValueOrUndefined(Number(values[quantity].text)),
        unit_id: getValueOrUndefined(values[quantity].unit_id),
        duration: `${addLeftPadding(durationHours)}:${addLeftPadding(durationMinutes)}:00`,
        start_time: `${addLeftPadding(startHours)}:${addLeftPadding(startMinutes)}:00`,
        end_time: `${addLeftPadding(endHours)}:${addLeftPadding(endMinutes)}:00`,
        textfield: getValueOrUndefined(values[comment].text),
        imagefile1: getValueOrUndefined(values[file].text),
        imagefileIds: orderAttachmentIds,
        geolocation: geolocation,
        set_dt: convertToLocalDate(new Date(values[deliveryDate].text)),
        route_length: getValueOrUndefined(Number(values[routeLength].text)),
    }

    handleOrderButtonAction('OK')

    try {
        await lambdaCall({
            body: saveBody,
            endpoint: apiEndPoint,
        })

        navigate('toimintaloki')
    } catch (error) {
        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        } else {
            dispatch(errorAction())
        }

        setIsLoading(false)
    }

    turnJobToOrder(false)
}

export const handleToggleChange = (orderAssignment, setOrderAssignment) => () => {
    setOrderAssignment(!orderAssignment)
}
