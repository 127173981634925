import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Loader, Table, Dimmer } from 'semantic-ui-react'
import IconButton from '../icon-button/icon-button'
import TableWithCheckbox from '../table-with-checkbox/table-with-checkbox'
import { groupAction } from '../../reducers/groups-reducer/groups-reducer'
import { getGroups } from '../../utils/redux-dispatch-configurations'
import {
    addChildGroup,
    groupComparer,
    handleCancel,
    handleDelete,
    handleNameChange,
    handleOpenForEdit,
    handleSave,
    removeChildGroup,
    setUserAndCustomerGroups,
} from './group-page.functions'
import PropTypes from 'prop-types'
import './group-page.css'

const fillRequiredText = 'Täytä kaikki vaadittavat tiedot'

const GroupPage = props => {
    const dispatch = useDispatch()
    const { loading, usergroups, customergroups, defaultCustomergroupId, defaultUsergroupId } = useSelector(state => state.groups)
    const [edit, setEdit] = useState(null)
    const [name, setName] = useState('')
    const [groups, setGroups] = useState([])
    const [subgroups, setSubgroups] = useState([])
    const [childGroups, setChildGroups] = useState([])
    const [defaultGroupId, setDefaultGroupId] = useState(null)
    const [subgroupDefaultId, setSubgroupDefaultId] = useState(null)
    const groupPageIsUsers = props.groupname === 'usergroups'
    const hasBothGroupTypes = customergroups && usergroups

    if (!loading && hasBothGroupTypes) {
        setUserAndCustomerGroups({
            groups,
            setGroups,
            usergroups,
            setSubgroups,
            customergroups,
            groupPageIsUsers,
            setDefaultGroupId,
            defaultUsergroupId,
            setSubgroupDefaultId,
            defaultCustomergroupId,
        })

        groups.sort(groupComparer)
    }

    useEffect(() => {
        (!usergroups || !customergroups) && dispatch(groupAction({}, getGroups))
    }, [usergroups, customergroups, dispatch])

    return (
        <>
            <h2>{props.title}</h2>
            <Dimmer.Dimmable dimmed={loading}>
                <Dimmer
                    active={loading}
                    inverted>
                    <Loader active={loading} />
                </Dimmer>
                <div>
                    <Table
                        celled
                        selectable
                        unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Ryhmän nimi
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    collapsing
                                    textAlign='center'>
                                    Tiedot
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {!edit &&
                                <Table.Row>
                                    <Table.Cell>
                                        <Input
                                            onChange={handleNameChange(setName)}
                                            placeholder='Ryhmän nimi'
                                            value={name}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <IconButton
                                            className='clear-orange-btn'
                                            icon='plus'
                                            onClick={handleSave({
                                                usergroup: groupPageIsUsers,
                                                edit,
                                                dispatch,
                                                groupAction,
                                                childGroups,
                                                setName,
                                                setEdit,
                                                fillRequiredText,
                                                name,
                                            })}
                                            text='Lisää'
                                        />
                                    </Table.Cell>
                                </Table.Row>}
                            {groups.map(group => {
                                if (edit && edit.id === group.id) {
                                    return (
                                        <Table.Row key={group.id}>
                                            <Table.Cell
                                                className='grouptable-active'
                                                colSpan='2'>
                                                <div>
                                                    <div className='grouptable-title'>
                                                        <Input
                                                            className='grouptable-input'
                                                            name='name'
                                                            onChange={handleNameChange(setName)}
                                                            placeholder='Ryhmän nimi'
                                                            required
                                                            value={name}
                                                        />
                                                        <IconButton
                                                            className='clear-orange-btn'
                                                            icon='save'
                                                            onClick={handleSave({
                                                                usergroup: groupPageIsUsers,
                                                                edit,
                                                                dispatch,
                                                                groupAction,
                                                                childGroups,
                                                                setName,
                                                                setEdit,
                                                                fillRequiredText,
                                                                name,
                                                            })}
                                                            text='Tallenna'
                                                        />
                                                        <IconButton
                                                            className='clear-orange-btn'
                                                            icon='cancel'
                                                            onClick={handleCancel(setEdit, setName)}
                                                            text='Peruuta'
                                                        />
                                                        <IconButton
                                                            className='clear-orange-btn'
                                                            icon='trash'
                                                            isDisabled={group.id === defaultGroupId}
                                                            onClick={handleDelete(
                                                                group,
                                                                dispatch,
                                                                groupAction,
                                                                groupPageIsUsers,
                                                                setEdit,
                                                                setName,
                                                            )}
                                                            text='Poista'
                                                        />
                                                    </div>
                                                    <TableWithCheckbox
                                                        add={addChildGroup(setChildGroups)}
                                                        checked={childGroups}
                                                        defaultGroupId={subgroupDefaultId}
                                                        items={subgroups}
                                                        remove={removeChildGroup(childGroups, setChildGroups)}
                                                        title={props.groupname === 'usergroups' ?
                                                            'Valitse asiakasryhmät' :
                                                            'Valitse käyttäjäryhmät'}
                                                    />
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }

                                return (
                                    <Table.Row
                                        key={group.id}>
                                        <Table.Cell
                                            className={group.id === defaultGroupId ?
                                                'defaultGroup-bold' :
                                                ''}
                                            data-testid='group'
                                        >{group.name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <IconButton
                                                className='clear-orange-btn'
                                                icon='edit'
                                                onClick={handleOpenForEdit(group, setEdit, setName, setChildGroups)}
                                                text='Avaa'
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                </div>
            </Dimmer.Dimmable>
        </>
    )
}

GroupPage.propTypes = {
    groupname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default GroupPage
