import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import storage from '../utils/storage'
import { loginReducer } from '../reducers/login-reducer/login-reducer'
import { notificationReducer } from '../reducers/notification-reducer/notification-reducer'
import { ordersReducer } from '../reducers/orders-reducer/orders-reducer'
import { groupsReducer } from '../reducers/groups-reducer/groups-reducer'
import { entryItemsReducer } from '../reducers/entry-items-reducer/entry-items-reducer'
import { usersReducer } from '../reducers/users-reducer/users-reducer'
import { reportsReducer } from '../reducers/reports-reducer/reports-reducer'
import { personalInformationReducer } from '../reducers/personal-information-reducer/personal-information-reducer'

const user = storage.loadUser() || null
const initialState = { userData: { user: user } }

const reducer = combineReducers({
    userData: loginReducer,
    notification: notificationReducer,
    orders: ordersReducer,
    entryItems: entryItemsReducer,
    groups: groupsReducer,
    users: usersReducer,
    report: reportsReducer,
    personalInformation: personalInformationReducer,
})

/**
 * Redux store - combines reducers and creates redux store with middleware
 * If userdata exists in session storage, it will initiate the redux store with userData.
 * If reducers are added inside the combineReducer, their state will be usable with useSelector hook
 */
const store = configureStore({
    reducer,
    preloadedState: initialState,
    middleware: [thunk],
    devTools: process.env.NODE_ENV !== 'production',
})

export default store
