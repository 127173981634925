import React, { useState } from 'react'
import { Modal, Button, Checkbox } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { handleAcceptEulaClick, handleCancelClick, handleCheckboxClick } from './eula-modal.functions'
import PropTypes from 'prop-types'

const EulaModal = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false)
    const [open, setOpen] = useState(true)

    return (
        <Modal
            dimmer='inverted'
            open={open}
        >
            <Modal.Header>EULA-versio: {props.eula.currentEulaVersion}</Modal.Header>
            <Modal.Content scrolling>
                {props.eula.eulaText}
            </Modal.Content>
            <Modal.Actions style={{ textAlign: 'center' }}>
                <Checkbox
                    data-testid='eulaCheckbox'
                    label='Sitoudun noudattamaan käyttöehtoja'
                    onChange={handleCheckboxClick(setChecked, checked)}
                    style={{ margin: '0.7em' }}
                />
                <Button
                    className='orange-white-btn'
                    content='Hyväksy'
                    data-testid={'eulaAcceptButton'}
                    disabled={!checked}
                    onClick={handleAcceptEulaClick(props.eula, props.setEulaAccepted, setOpen, dispatch)}
                />
                <Button
                    className='orange-white-btn'
                    content='Peruuta'
                    onClick={handleCancelClick(dispatch, navigate)}
                />
            </Modal.Actions>
        </Modal>
    )
}

EulaModal.propTypes = {
    eula: PropTypes.object.isRequired,
    setEulaAccepted: PropTypes.func.isRequired,
}

export default EulaModal
