import React, { useState } from 'react'
import { Icon, Modal } from 'semantic-ui-react'
import PersonalInformation from '../../screens/personal-information/personal-information'
import { useDispatch } from 'react-redux'
import { handleLogoutClick, handleModalVisibilityClick } from './user-button.functions'
import PropTypes from 'prop-types'

const UserButton = props => {
    const [isVisible, setIsVisible] = useState(false)
    const dispatch = useDispatch()

    return (
        <>
            <div
                className='user-div hover'
                onClick={handleModalVisibilityClick(setIsVisible, true)}
            >
                <Icon
                    className="user-icon"
                    name='user' />
                <div className='hide-in-mobile-tight user-icon'>
                    {props.user.user_firstname} {props.user.user_lastname}
                </div>
            </div>
            <div
                className='user-div hover hide-in-mobile'
                onClick={handleLogoutClick(dispatch)}>
                <Icon name='log out' />
            </div>
            <Modal
                dimmer='inverted'
                open={isVisible}
                size={'small'}
            >
                <Modal.Content>
                    <PersonalInformation onClose={handleModalVisibilityClick(setIsVisible, false)} />
                </Modal.Content>
            </Modal>
        </>
    )
}

UserButton.propTypes = { user: PropTypes.object.isRequired }

export default UserButton
