import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { successAction, errorAction } from '../../reducers/notification-reducer/notification-reducer'

export const handleResetClick = (email, setLoading, dispatch, navigate) => async e => {
    e.preventDefault()

    if (email.value.length !== 0 && !email.error) {
        setLoading(true)

        try {
            await lambdaCall({ body: { email: email.value }, endpoint: awsApiEndpoints.sendPasswordChangeLink })
            dispatch(successAction('Sähköposti lähetetty'))

            setTimeout(() => {
                navigate('/login')
            }, 5000)
        } catch (error) {
            dispatch(errorAction('Jokin meni pieleen'))
        }
    } else { dispatch(errorAction('Syötä oikea sähköpostiosoite')) }

    setLoading(false)
}
