import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input } from 'semantic-ui-react'
import TableWithEdit from '../table-with-edit/table-with-edit'
import { useForm } from '../../hooks'
import './entry-item-page.css'
import PropTypes from 'prop-types'
import {
    handleEditChange,
    handleCancelClick,
    handleDeleteClick,
    handleSaveClick,
    fieldShouldBeDisabled,
    getOptionsForSelect,
    getPlaceholderForSelect,
} from './entry-item-page.functions'

const EntryItemPage = props => {
    const initialValues = props.inputFields.reduce((accumulated, current) => ({ ...accumulated, [current.valueName]: '' }), {})
    const { values, setValues, handleChange, clearFields } = useForm(initialValues)
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch()

    return (
        <>
            <h2>Hallinnoi kirjaustietoja</h2>
            <div className='add-values-container'>
                <form
                    className='add-values-new'
                    id='form-input'>
                    {edit ? <h3>Muokkaa tietoja</h3> : <h3>{props.title}</h3>}
                    <div
                        className='add-values-form'
                        id='add-form'>
                        {props.inputFields.map(inputField => {
                            switch (inputField.type) {
                                case 'text':
                                    return (
                                        <div
                                            className='add-values-input'
                                            key={inputField.valueName}>
                                            <label>{inputField.label}</label>
                                            <Input
                                                name={inputField.valueName}
                                                onChange={handleChange}
                                                placeholder={inputField.placeholder}
                                                value={values[inputField.valueName] ? values[inputField.valueName] : ''}
                                            />
                                        </div>)
                                case 'select':
                                    return (
                                        <div
                                            className='add-values-input'
                                            key={inputField.valueName}>
                                            <label>{inputField.label}</label>
                                            <div className='add-values-select'>
                                                <Form.Select
                                                    data-testid={inputField.valueName}
                                                    disabled={fieldShouldBeDisabled(inputField, values)}
                                                    fluid
                                                    name={inputField.valueName}
                                                    onChange={handleChange}
                                                    options={getOptionsForSelect({ inputField, props, values })}
                                                    placeholder={getPlaceholderForSelect({ inputField, props, values })}
                                                    value={values[inputField.valueName]}
                                                />

                                            </div>
                                        </div>)
                                default:
                                    return <div key={inputField.valueName}>{inputField.valueName}</div>
                            }
                        })}
                    </div>
                    <div className='flex'>
                        {edit ?
                            <>
                                <Button
                                    className='orange-white-btn'
                                    onClick={handleCancelClick(setEdit, clearFields)}>Peruuta</Button>
                                <Button
                                    className='orange-white-btn'
                                    onClick={handleDeleteClick(edit, props, dispatch)}>Poista</Button>
                                <Button
                                    className='orange-white-btn'
                                    onClick={handleSaveClick(values, props, edit, dispatch)}>Tallenna</Button>
                            </> :
                            <Button
                                className='orange-white-btn'
                                onClick={handleSaveClick(values, props, edit, dispatch)}>Lisää uusi</Button>
                        }
                    </div>
                </form>
                <TableWithEdit
                    columns={props.tableColumns}
                    edit={edit}
                    items={props.data[props.tableItems]}
                    onEdit={handleEditChange(setEdit, setValues)} />
            </div>
        </>
    )
}

EntryItemPage.defaultProps = { data: null }

EntryItemPage.propTypes = {
    data: PropTypes.object,
    inputFields: PropTypes.array.isRequired,
    tableColumns: PropTypes.array.isRequired,
    tableItems: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default EntryItemPage
