import { editPersonalInformation } from '../../utils/redux-dispatch-configurations'
import { personalInformationAction } from '../../reducers/personal-information-reducer/personal-information-reducer'
import { errorAction, successAction } from '../../reducers/notification-reducer/notification-reducer'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'

export const handleSaveClick = (dispatch, values, user) => e => {
    e.preventDefault()

    return dispatch(personalInformationAction({
        phone_number: values.phone_number,
        access_token: user.access_token,
    }, editPersonalInformation))
}

export const handleCloseModalClick = onClose => onClose

export const sendLinkForPasswordChange = (setSendLinkPending, user, dispatch) => async () => {
    setSendLinkPending(true)

    try {
        const result = await lambdaCall({
            body: { email: user.email },
            endpoint: awsApiEndpoints.sendPasswordChangeLink,
        })

        result?.status === 200 && dispatch(successAction('Sähköposti lähetetty'))
    } catch {
        dispatch(errorAction('Sähköpostin lähetys epäonnistui'))
    }

    setSendLinkPending(false)
}
