export const limit = 50

export const worklogSchema = {
    1: [
        {
            limit: limit,
            limit_start: 0,
            orderassignment: false,
            user_action: 'get_own_orders',
            worker_or_user_string: 'userExists',
        },
        [false, 'userExists', 'orders'],
    ],
    2: [
        {
            limit: limit,
            limit_start: 0,
            orderassignment: false,
            user_action: 'get_all_orders',
        },
        [false, '', 'orders'],
    ],
    3: [
        {
            limit: limit,
            limit_start: 0,
            orderassignment: true,
            user_action: 'get_open_and_assigned_orderassignments',
            worker_or_user_string: 'workerExists',
        },
        [true, 'workerExists', 'ownAndAssignedOrderassignments'],
    ],
    4: [
        {
            limit: limit,
            limit_start: 0,
            orderassignment: true,
            user_action: 'get_open_and_assigned_orderassignments',
            worker_or_user_string: 'workerIsNull',
        },
        [true, 'workerIsNull', 'ownAndAssignedOrderassignments'],
    ],
    5: [
        {
            limit: limit,
            limit_start: 0,
            orderassignment: true,
            user_action: 'get_own_orderassignments',
            worker_or_user_string: 'userExists',
        },
        [true, 'userExists', 'orderassignments'],
    ],
    6: [
        {
            limit: limit,
            limit_start: 0,
            orderassignment: true,
            user_action: 'get_all_orderassignments',
        },
        [true, '', 'orderassignments'],
    ],
}
