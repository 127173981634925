import { lambdaCall } from '../../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../../utils/aws-api-endpoints'

const worksitelessText = '-- Työmaattomat --'

let joinedOptionalValues
let types
let worksites

export let optionalValues = []

export const handleDateChange = (setDate, setShowBoxes, setCustomer) => date => {
    setDate(date)
    setShowBoxes(false)
    setCustomer(null)
}

export const handleResetClick = ({
    setFromDate,
    setToDate,
    setCustomer,
    setTypeDropdown,
    setWorksiteDropdown,
    setReport,
    setShowBoxes,
    startOfDateMonthAgo,
    endOfCurrentDate,
}) => () => {
    setFromDate(startOfDateMonthAgo)
    setToDate(endOfCurrentDate)
    setCustomer(null)
    setTypeDropdown([])
    setWorksiteDropdown([])
    setReport(null)
    setShowBoxes(false)
    optionalValues = []
}

export const handleCustomerChange = ({
    setReport,
    setPending,
    setCustomer,
    orderReportCheckboxes,
    allTypesString,
    setTypeId,
    firstDate,
    secondDate,
    setShowBoxes,
    setResult,
    setCurrentType,
    setCurrentWorksite,
    allWorksitesString,
    throwError,
    setWorksiteDropdown,
    setTypeDropdown,
}) => async (_e, data) => {
    setReport(null)
    setPending(true)
    setCustomer(data.value)
    optionalValues = []

    setTypeId(null)
    orderReportCheckboxes[0].checkedCheckboxes.fill(0)

    const body = {
        output: 'työmaa,tyyppi',
        from_date: firstDate,
        to_date: secondDate,
        conditions: { asiakas: data.value },
    }

    try {
        const result = await lambdaCall({ body: body, endpoint: awsApiEndpoints.reportEntryItems })

        setShowBoxes(true)
        setResult(result)
        worksiteDropdownSet(result, allWorksitesString, setWorksiteDropdown)
        typeDropdownSet(result, allTypesString, setTypeDropdown)
        setCurrentType(allTypesString)
        setCurrentWorksite(allWorksitesString)
        setPending(false)
    } catch (error) {
        throwError(error)
    }
}

const emptyStringsAndNullsAsText = a => a.map(x => x || worksitelessText)

const mapObjectForDropdown = data =>
    // Map worksites/types to required object for dropdown
    data.map((item, i) => ({ key: i, value: item, text: item }))

const worksiteDropdownSet = (data, allWorksitesString, setWorksiteDropdown) => {
    const worksitesAll = data.data.map(item => item.työmaa)

    worksites = [...new Set(worksitesAll)]
    worksites.unshift(allWorksitesString)
    worksites = emptyStringsAndNullsAsText(worksites)
    setWorksiteDropdown(mapObjectForDropdown(worksites))
}

const typeDropdownSet = (data, allTypesString, setTypeDropdown) => {
    const typesAll = data.data.map(item => item.tyyppi)

    types = [...new Set(typesAll)]
    types.unshift(allTypesString)
    types = emptyStringsAndNullsAsText(types)
    setTypeDropdown(mapObjectForDropdown(types))
}

export const handleTypeChange = ({
    checkedCheckboxesArray,
    orderReportCheckboxes,
    setTypeId,
    allTypesString,
    result,
    allWorksitesString,
    setWorksiteDropdown,
    setCurrentType,
}) => (_e, data) => {
    const typeNameForFilteringArray = orderReportCheckboxes.filter(x => x.type === data.value)

    setTypeId(typeNameForFilteringArray[0].id)

    if (!checkedCheckboxesArray.includes(0)) {
        typeNameForFilteringArray[0].checkedCheckboxes.fill(1)
        typeNameForFilteringArray[0].labels.shift()
        optionalValues = typeNameForFilteringArray[0].labels
    } else {
        optionalValues = []
        typeNameForFilteringArray[0].checkedCheckboxes.fill(0)
    }

    if (data.value === allTypesString) {
        worksiteDropdownSet(result, allWorksitesString, setWorksiteDropdown)
        setCurrentType(data.value)
    } else {
        let keys = []

        result.data.forEach(kvp => {
            kvp.tyyppi === data.value && keys.push(kvp.työmaa)
        })

        keys.unshift(allWorksitesString)
        keys = emptyStringsAndNullsAsText(keys)
        setCurrentType(data.value)
        setWorksiteDropdown(mapObjectForDropdown(keys))
    }
}

export const handleWorksiteChange = ({
    allWorksitesString,
    result,
    allTypesString,
    setTypeDropdown,
    setCurrentWorksite,
}) => (_e, data) => {
    if (data.value === allWorksitesString || data.value === worksitelessText) {
        typeDropdownSet(result, allTypesString, setTypeDropdown)
        setCurrentWorksite(data.value)
    } else {
        let keys = []

        result.data.forEach(kvp => {
            kvp.työmaa === data.value && keys.push(kvp.tyyppi)
        })

        keys.unshift(allTypesString)
        keys = emptyStringsAndNullsAsText(keys)
        setCurrentWorksite(data.value)
        setTypeDropdown(mapObjectForDropdown(keys))
    }
}

export const dropdownShouldBeDisabled = dropdown => dropdown.length < 1

export const dropdownItemIsEmpty = (current, all) => current === '' ? all : current

export const checkAllBoxes = (orderReportCheckboxes, typeId, setRender, render) => _e => {
    const typeObject = orderReportCheckboxes.find(checkbox => checkbox.id === typeId)

    if (typeObject.checkedCheckboxes.includes(0)) {
        typeObject.checkedCheckboxes.fill(1)
    } else {
        typeObject.checkedCheckboxes.fill(0)
        optionalValues = []
    }

    setRender(!render)
}

const getJoinedOptionalValues = (typeId, orderReportCheckboxes, startAndFinishTimeText) => {
    const typeObject = orderReportCheckboxes.filter(type => type.id === typeId)

    if (!typeObject[0].checkedCheckboxes.includes(0)) {
        typeObject[0].labels.shift()
        optionalValues = typeObject[0].labels
    }

    joinedOptionalValues = optionalValues.map(x => {
        if (x === startAndFinishTimeText)
            return 'AAA_AAA_Aloitus,AAA_AAA_Lopetus'

        return x
    })

    joinedOptionalValues = joinedOptionalValues.length === 0 ? null : joinedOptionalValues.join(',')
}

export const createPdf = ({
    customer,
    setLoading,
    allWorksitesString,
    currentWorksite,
    typeId,
    firstDate,
    secondDate,
    billed,
    setReport,
    dispatch,
    errorAction,
    startAndFinishTimeText,
    convertToLocalDate,
    orderReportCheckboxes,
    loading,
}) => async () => {
    if (!customer) {
        dispatch(errorAction('Valitse asiakas'))

        return
    }

    if (loading)
        return

    getJoinedOptionalValues(typeId, orderReportCheckboxes, startAndFinishTimeText)
    setLoading(true)

    const worksiteName = [allWorksitesString, worksitelessText].includes(currentWorksite) ? null : currentWorksite

    const body = {
        report_type: 'orders_report',
        customer_name: customer,
        worksite_name: worksiteName,
        type_id: typeId,
        set_dt_strt: convertToLocalDate(firstDate),
        set_dt_end: convertToLocalDate(secondDate),
        billed: billed,
        optional: joinedOptionalValues,
    }

    try {
        const res = await lambdaCall({ body: body, endpoint: awsApiEndpoints.reports })

        setReport(JSON.parse(res.data.orders_report[0][0].content))
    } catch (error) {
        dispatch(errorAction())
    }

    setLoading(false)
}

export const handleBilled = setBilled => (_e, data) => {
    setBilled(data.value)
}

export const renderFunction = (setRender, render) => () => {
    setRender(!render)
}

export const getReportEntryItems = async ({
    body,
    setCustomers,
    setWorksiteDropdown,
    setTypeDropdown,
    throwError,
    setCustomerPending,
}) => {
    try {
        const res = await lambdaCall({ body: body, endpoint: awsApiEndpoints.reportEntryItems })

        const mappedCustomers = res.data.map((customer, i) => {
            customer.text = customer.asiakas
            customer.value = customer.asiakas
            customer.key = i

            return customer
        })

        setCustomers(mappedCustomers)
        setWorksiteDropdown([])
        setTypeDropdown([])
    } catch (error) {
        throwError(error)
    }

    setCustomerPending(false)
}
