import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Loader, Image, Modal, Divider, Button } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { errorAction, expiredTokenAction } from '../../../reducers/notification-reducer/notification-reducer'
import IconButton from '../../icon-button/icon-button'
import { loginAction } from '../../../reducers/login-reducer/login-reducer'
import { logout } from '../../../utils/redux-dispatch-configurations'
import PropTypes from 'prop-types'
import { handleImageUpload, handleFileDelete, getImageLinks, handleModalToggle, handleInputRefClick } from './file-selector.functions'

const FileSelector = props => {
    const fileInputRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [previewFiles, setPreviewFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const { orderId, setOrderId } = props

    const throwError = useCallback(error => {
        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        } else {
            dispatch(errorAction())
        }
    }, [dispatch])

    const setObjectUrls = useCallback((objects, existingOrderId) => {
        for (const [i] of Object.entries(objects)) {
            previewFiles.push({
                id: objects[i].id,
                url: objects[i].url,
                key: objects[i].key,
            })

            if (existingOrderId)
                props.selected.push(objects[i].id)
        }
    }, [previewFiles, props.selected])

    useEffect(() => {
        if (orderId === 0)
            return

        setIsLoading(true)

        getImageLinks(null, orderId, setObjectUrls, throwError, setIsLoading)
        setOrderId(0)
    }, [setObjectUrls, orderId, setOrderId, throwError])

    return (
        <div
            className={previewFiles.length ? 'selector-box selector-selected' : 'selector-box'}
            data-testid='file-selector-box'
        >
            <h3>{props.title.toUpperCase()}</h3>
            {previewFiles.length ?
                <Modal
                    className='selector-modal fileselector-modal-size'
                    dimmer='inverted'
                    onClose={handleModalToggle(setIsOpen, false)}
                    onOpen={handleModalToggle(setIsOpen, true)}
                    open={isOpen}
                    trigger={<div
                        className='removable-item'
                        data-testid='modal-trigger'
                        style={{ cursor: 'pointer' }}>{`Liitteet (${previewFiles.length})`}</div>}
                >
                    {previewFiles.map((image, index) => {
                        const isDivider = index + 1 === previewFiles.length

                        return (
                            <div
                                className='file-image-preview'
                                data-testid='file-image-preview'
                                key={image.id}>
                                <Image
                                    alt='Kuvaa ei voida näyttää'
                                    key={image.id}
                                    src={image.url}
                                />
                                <Button
                                    className='file-delete-btn'
                                    content='Poista'
                                    data-testid={image.key}
                                    id={image.id}
                                    onClick={handleFileDelete({
                                        index: image.id,
                                        key: image.key,
                                        setPreviewFiles,
                                        previewFiles,
                                        setSelected: props.setSelected,
                                        setIsOpen,
                                        dispatch,
                                        throwError,
                                        setIsLoading,
                                    })}
                                    size='large'
                                    style={{ bottom: isDivider ? '0.3em' : 'calc(28px + 0.3em)' }}
                                />
                                <Divider
                                    fitted={isDivider}
                                    hidden
                                    id={image.id} />
                            </div>
                        )
                    })}
                    <div className='file-selector-footer'>
                        <Button
                            className='sticky-btn'
                            content='Sulje'
                            onClick={handleModalToggle(setIsOpen, false)}
                        />
                    </div>
                </Modal> :
                <div
                    className='removable-item-placeholder'
                >...</div>
            }
            {isLoading ?
                <Loader
                    active={isLoading}
                    className='fileSelector-loader'
                    inline='centered' /> :
                <div
                    className='column-flex'
                >
                    <IconButton
                        as='label'
                        className='selector-btn'
                        htmlFor='file'
                        icon='plus'
                        onClick={handleInputRefClick(fileInputRef)}
                        text='Valitse'
                    />
                    <input
                        accept='image/*'
                        data-testid='file-selector-attachment-input'
                        hidden
                        onChange={handleImageUpload(
                            dispatch,
                            setIsLoading,
                            props.selected,
                            setObjectUrls,
                            throwError,
                            previewFiles
                        )}
                        ref={fileInputRef}
                        type='file'
                    />
                </div>
            }
        </div>
    )
}

FileSelector.propTypes = {
    orderId: PropTypes.number.isRequired,
    selected: PropTypes.array.isRequired,
    setOrderId: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}

export default FileSelector
