import React, { useState, useEffect, useCallback } from 'react'
import { Button, Select, Loader } from 'semantic-ui-react'
import DateInterval from '../../../components/date-interval/date-interval'
import PdfEmbed from '../../../components/pdf-embed/pdf-embed'
import CheckboxOptional from '../../../components/checkbox-optional/checkbox-optional'
import { errorAction, expiredTokenAction } from '../../../reducers/notification-reducer/notification-reducer'
import { useDispatch } from 'react-redux'
import { loginAction } from '../../../reducers/login-reducer/login-reducer'
import { logout } from '../../../utils/redux-dispatch-configurations'
import { sortFromAndToDates, startOfDateMonthAgo, endOfCurrentDate, convertToLocalDate } from '../../../utils/time'
import {
    handleDateChange,
    handleResetClick,
    handleCustomerChange,
    handleTypeChange,
    handleWorksiteChange,
    dropdownShouldBeDisabled,
    dropdownItemIsEmpty,
    checkAllBoxes,
    createPdf,
    handleBilled,
    renderFunction,
    optionalValues,
    getReportEntryItems,
} from './orders-report.functions'

const allTypesString = '-- Kaikki tyypit --'
const allWorksitesString = '-- Kaikki työmaat --'
const billedText = 'Laskutetut'
const commentText = 'Kommentti'
const everythingText = 'Kaikki'
const pickupText = 'Noutopaikka'
const selectEverythingText = 'Valitse kaikki'
const startAndFinishTimeText = 'Aloitus- ja lopetusaika'
const workerText = 'Työntekijä'
const unbilledText = 'Laskuttamattomat'

const billedDropdown = [
    { key: everythingText, text: everythingText, value: 2 },
    { key: billedText, text: billedText, value: 1 },
    { key: unbilledText, text: unbilledText, value: 0 },
]

// travel distance removed until procedure fixed https://sw-tech.atlassian.net/browse/MARANET-1147
const getOrderReportCheckboxes = checkedCheckboxes =>
    [
        {
            type: allTypesString,
            labels: [selectEverythingText, commentText, workerText, startAndFinishTimeText, pickupText],
            id: null,
            checkedCheckboxes: checkedCheckboxes,
        },
        {
            type: 'Kuljetus',
            labels: [selectEverythingText, commentText, workerText, startAndFinishTimeText, pickupText],
            id: 1,
            checkedCheckboxes: checkedCheckboxes,
        },
        {
            type: 'Kaivuutyö',
            labels: [selectEverythingText, commentText, workerText, startAndFinishTimeText],
            id: 2,
            checkedCheckboxes: checkedCheckboxes,
        },
        {
            type: 'Tuntiajo',
            labels: [selectEverythingText, commentText, workerText, startAndFinishTimeText],
            id: 3,
            checkedCheckboxes: checkedCheckboxes,
        },
        {
            type: 'Tarvike',
            labels: [selectEverythingText, commentText],
            id: 4,
            checkedCheckboxes: checkedCheckboxes,
        },
        {
            type: 'Henkilötyö',
            labels: [selectEverythingText, commentText, startAndFinishTimeText],
            id: 5,
            checkedCheckboxes: checkedCheckboxes,
        },
        {
            type: '',
            labels: [selectEverythingText, commentText, workerText, startAndFinishTimeText, pickupText],
            id: null,
            checkedCheckboxes: checkedCheckboxes,
        },
    ]

const checkedCheckboxesArray = [0, 0, 0, 0, 0]

const OrdersReport = () => {
    const [report, setReport] = useState()
    const [fromDate, setFromDate] = useState(startOfDateMonthAgo)
    const [toDate, setToDate] = useState(endOfCurrentDate)
    const [customers, setCustomers] = useState([])
    const [worksiteDropdown, setWorksiteDropdown] = useState([])
    const [typeDropdown, setTypeDropdown] = useState([])
    const [result, setResult] = useState([])
    const [currentType, setCurrentType] = useState()
    const [currentWorksite, setCurrentWorksite] = useState()
    const [customerPending, setCustomerPending] = useState(false)
    const [pending, setPending] = useState(false)
    const [render, setRender] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [typeId, setTypeId] = useState(null)
    const [billed, setBilled] = useState(2)
    const [showBoxes, setShowBoxes] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { firstDate, secondDate } = sortFromAndToDates(fromDate, toDate)
    const orderReportCheckboxes = getOrderReportCheckboxes(checkedCheckboxesArray)

    const throwError = useCallback(error => {
        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        } else {
            dispatch(errorAction())
        }
    }, [dispatch])

    useEffect(() => {
        const body = {
            output: 'asiakas',
            from_date: sortFromAndToDates(fromDate, toDate).firstDate,
            to_date: sortFromAndToDates(fromDate, toDate).secondDate,
        }

        getReportEntryItems({ body, setCustomers, setWorksiteDropdown, setTypeDropdown, throwError, setCustomerPending })
        setReport(null)
        setCustomerPending(true)
    }, [fromDate, toDate, throwError])

    return (
        <div>
            <div className='reports-description'>
                <h2>Laskuliite</h2>
                <div>
                    Laskuliite hakee kirjaukset valittujen tietojen perusteella ja tuottaa niistä PDF dokumentin, joka käy laskuliitteeksi.
                </div>
            </div>
            <div className='reports-container'>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Toimituksen aikaväli:</h4>
                    </div>
                    <DateInterval
                        firstDate={fromDate}
                        secondDate={toDate}
                        setFromDate={handleDateChange(setFromDate, setShowBoxes, setCustomer)}
                        setToDate={handleDateChange(setToDate, setShowBoxes, setCustomer)}
                    />
                </div>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Asiakas:</h4>
                    </div>
                    <div className='reports-select'>
                        {customerPending ?
                            <Loader
                                active
                                inline='centered'
                                size='medium' /> :
                            <Select
                                closeOnBlur
                                closeOnChange
                                onChange={handleCustomerChange({
                                    setReport,
                                    setPending,
                                    setCustomer,
                                    orderReportCheckboxes,
                                    allTypesString,
                                    setTypeId,
                                    firstDate,
                                    secondDate,
                                    setShowBoxes,
                                    setResult,
                                    setCurrentType,
                                    setCurrentWorksite,
                                    allWorksitesString,
                                    throwError,
                                    setWorksiteDropdown,
                                    optionalValues,
                                    setTypeDropdown,
                                })}
                                options={customers}
                                placeholder='Valitse Asiakas'
                                search
                                selectOnNavigation={false}
                                value={customer}
                            />}
                    </div>
                </div>

                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Tyyppi:</h4>
                    </div>
                    <div className='reports-select'>
                        {pending ?
                            <Loader
                                active
                                inline='centered'
                                size='medium' /> :
                            <Select
                                clearable
                                closeOnChange
                                disabled={dropdownShouldBeDisabled(typeDropdown)}
                                onChange={handleTypeChange({
                                    checkedCheckboxesArray,
                                    orderReportCheckboxes,
                                    setTypeId,
                                    allTypesString,
                                    result,
                                    allWorksitesString,
                                    setWorksiteDropdown,
                                    setCurrentType,
                                    setRender,
                                    render,
                                })}
                                options={typeDropdown}
                                search
                                selectOnNavigation={false}
                                selection
                                value={dropdownItemIsEmpty(currentType, allTypesString)}
                            />
                        }
                    </div>
                </div>

                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Työmaa:</h4>
                    </div>
                    <div className='reports-select'>
                        {pending ?
                            <Loader
                                active
                                inline='centered'
                                size='medium' /> :
                            <Select
                                className='long-words'
                                clearable
                                closeOnChange
                                disabled={dropdownShouldBeDisabled(worksiteDropdown)}
                                onChange={handleWorksiteChange({
                                    allWorksitesString,
                                    result,
                                    allTypesString,
                                    setTypeDropdown,
                                    setCurrentWorksite,
                                })}
                                options={worksiteDropdown}
                                search
                                selectOnNavigation={false}
                                selection
                                value={dropdownItemIsEmpty(currentWorksite, allWorksitesString)}
                            />}
                    </div>
                </div>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Laskutus:</h4>
                    </div>
                    <div className='reports-select'>
                        <Select
                            defaultValue={2}
                            onChange={handleBilled(setBilled)}
                            options={billedDropdown}
                            selectOnNavigation={false}
                            value={billedDropdown.key}
                        />
                    </div>
                </div>
                <div className='selector-grid-reports'>
                    <div className='reports-selector-box reports-selector-header'>
                        <h4>Lisätiedot:</h4>
                    </div>
                    <div className='reports-checkbox-container'>
                        {showBoxes ?
                            // Display checkboxes based on selected type
                            orderReportCheckboxes.filter(item => item.type === currentType).map(i => i.labels.map((label, id) =>
                                <ul key={label}>
                                    <li className='rptbox'>
                                        <CheckboxOptional
                                            checkAll={checkAllBoxes(orderReportCheckboxes, typeId, setRender, render)}
                                            checkedCheckboxes={i.checkedCheckboxes}
                                            id={id}
                                            name={label}
                                            optionalValues={optionalValues}
                                            renderFunction={renderFunction(setRender, render)}
                                            values={i}
                                        />
                                    </li>
                                    <li>
                                        <strong className='rpttext'>{label}</strong>
                                    </li>
                                </ul>)) :
                            null}
                    </div>
                </div>
                <div className='selector-box-dl-btn'>
                    <Button
                        className='orange-white-btn'
                        content='Lataa'
                        disabled={!customers.length}
                        icon='download'
                        onClick={createPdf({
                            customer,
                            setLoading,
                            allWorksitesString,
                            currentWorksite,
                            typeId,
                            firstDate,
                            secondDate,
                            billed,
                            setReport,
                            dispatch,
                            errorAction,
                            startAndFinishTimeText,
                            convertToLocalDate,
                            orderReportCheckboxes,
                            loading,
                        })} />
                    <Button
                        className='orange-white-btn'
                        content='Tyhjennä'
                        icon='delete'
                        onClick={handleResetClick({
                            setFromDate,
                            setToDate,
                            setCustomer,
                            setTypeDropdown,
                            setWorksiteDropdown,
                            checkedCheckboxes: checkedCheckboxesArray,
                            setReport,
                            setShowBoxes,
                            startOfDateMonthAgo,
                            endOfCurrentDate,
                        })} />
                </div>
            </div>,

            {loading ?
                <Loader
                    active
                    className='content-loader'
                    inline='centered' /> :
                <PdfEmbed json={report} />}
        </div>
    )
}

export default OrdersReport
