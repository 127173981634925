import { createGroup, deleteGroup, editGroup } from '../../utils/redux-dispatch-configurations'
import { errorAction } from '../../reducers/notification-reducer/notification-reducer'

export const groupComparer = (a, b) => {
    const aLower = a.name.toLowerCase()
    const bLower = b.name.toLowerCase()

    if (aLower < bLower)
        return -1

    if (aLower > bLower)
        return 1

    return 0
}

export const setUserAndCustomerGroups = ({
    groups,
    setGroups,
    usergroups,
    setSubgroups,
    customergroups,
    groupPageIsUsers,
    setDefaultGroupId,
    defaultUsergroupId,
    setSubgroupDefaultId,
    defaultCustomergroupId,
}) => {
    if (groupPageIsUsers && groups !== usergroups) {
        setGroups(usergroups)
        setSubgroups(customergroups)
        setDefaultGroupId(defaultUsergroupId)
        setSubgroupDefaultId(defaultCustomergroupId)
    }

    if (!groupPageIsUsers && groups !== customergroups) {
        setGroups(customergroups)
        setSubgroups(usergroups)
        setDefaultGroupId(defaultCustomergroupId)
        setSubgroupDefaultId(defaultUsergroupId)
    }
}

export const handleSave = ({
    usergroup,
    edit,
    dispatch,
    groupAction,
    childGroups,
    setName,
    setEdit,
    fillRequiredText,
    name,
}) => () => {
    const saveBody = { usergroup, name: name }

    if (edit) {
        if (name !== '') {
            dispatch(groupAction({ ...saveBody, id: edit.id, groups: childGroups }, editGroup))
            setName('')
            setEdit(null)
        } else {
            dispatch(errorAction(fillRequiredText))
        }

        return
    }

    if (name !== '') {
        dispatch(groupAction(saveBody, createGroup))
        setName('')
    } else {
        dispatch(errorAction(fillRequiredText))
    }
}

export const handleOpenForEdit = (group, setEdit, setName, setChildGroups) => () => {
    setEdit(group)
    setName(group.name)
    setChildGroups(group.subgroups)
}

export const handleCancel = (setEdit, setName) => () => {
    setEdit()
    setName('')
}

export const handleDelete = (group, dispatch, groupAction, usergroup, setEdit, setName) => () => {
    dispatch(groupAction({ id: group.id, usergroup }, deleteGroup))
    setEdit(null)
    setName('')
}

export const handleNameChange = setName => ({ target }) => setName(target.value)

export const addChildGroup = setChildGroups => item => {
    setChildGroups(initial => [...initial, item.id])
}

export const removeChildGroup = (childGroups, setChildGroups) => item => {
    const newGroups = childGroups.filter(group => group !== item.id)

    setChildGroups(newGroups)
}
