import storage from '../../utils/storage'
import { expiredTokenAction, errorAction, successAction } from '../notification-reducer/notification-reducer'
import { loginAction } from '../login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'

const initialState = {}
const EDIT_INFO_REQ = 'EDIT-INFORMATION-REQUEST'
const EDIT_INFO_SUCCESS = 'EDIT-INFORMATION'
const EDIT_INFO_FAILURE = 'EDIT-INFORMATION-FAILURE'

// REDUCERS
export const personalInformationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case EDIT_INFO_REQ:
            return { ...state, loading: true }
        case EDIT_INFO_SUCCESS:
            return { ...state, data: action.payload, loading: false }
        case EDIT_INFO_FAILURE:
            return { ...state, loading: false }
        default:
            return state
    }
}

// ACTION CREATORS
export const personalInformationAction = (body, config) => async dispatch => {
    try {
        dispatch({ type: config.request })

        const information = await lambdaCall({ body: body, endpoint: config.endpoint })
        const user = storage.loadUser()

        const userToStore = {
            ...user,
            phone: information.data.phone,
        }

        storage.saveUser(userToStore)

        dispatch({
            type: config.dispatchType,
            payload: information.data,
        })

        dispatch(successAction())
    } catch (error) {
        dispatch({ type: EDIT_INFO_FAILURE })
        dispatch(errorAction())

        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        }
    }
}
