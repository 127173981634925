import { awsApiEndpoints } from '../../../utils/aws-api-endpoints'
import { lambdaCall } from '../../../services/aws-lambda-service/aws-lambda-service'
import { loginAction } from '../../../reducers/login-reducer/login-reducer'
import { logout } from '../../../utils/redux-dispatch-configurations'
import fileDownload from 'js-file-download'
import { errorAction, expiredTokenAction } from '../../../reducers/notification-reducer/notification-reducer'

const blobType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }

export const handleCheckboxToggle = (setIsChecked, isChecked) => () => {
    setIsChecked(!isChecked)
}

export const handleFromDateChange = setFromDate => date => setFromDate(date)

export const handleToDateChange = setToDate => date => setToDate(date)

export const handleSortOrderChange = setSortOrder => e => {
    setSortOrder(e)
}

export const createExcel = ({ setLoading, isChecked, customerForm, sortOrder, firstDate, secondDate, dispatch, fileName }) => async () => {
    setLoading(true)

    const formData = new FormData(customerForm.current)
    const { customer } = Object.fromEntries(formData)

    const body = {
        all_info: isChecked,
        customer_name: customer,
        sort_order: sortOrder,
        from_date: firstDate,
        to_date: secondDate,
    }

    try {
        const result = await lambdaCall({ body: body, endpoint: awsApiEndpoints.excel })

        if (result.data.length === 0) {
            dispatch(errorAction('Raportti ei sisältänyt kirjauksia'))
            setLoading(false)

            return
        }

        const byteCharacters = window.atob(result.data) // Node.js atob is deprecated, but window.atob is not
        const byteNumbers = byteCharacters.split('').map(char => char.charCodeAt(0))
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], blobType)

        fileDownload(blob, fileName)
    } catch (error) {
        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        } else {
            console.error(error)
            dispatch(errorAction())
        }
    }

    setLoading(false)
}

export const handleKeyPress = createExcelFunction => e => {
    if (e.key === 'Enter') {
        e.preventDefault()
        createExcelFunction()
    }
}
