import { awsApiEndpoints } from '../../../utils/aws-api-endpoints'
import { lambdaCall } from '../../../services/aws-lambda-service/aws-lambda-service'
import { errorAction } from '../../../reducers/notification-reducer/notification-reducer'
import { getObjectAsKeyValueTextArray, nullish } from '../../../utils/helper-functions'
import { startOfDateMonthAgo, endOfCurrentDate, convertToLocalDate } from 'src/utils/time'
import {
    dropdownBillingOptions,
    dropdownOrderTypes,
    allOptions,
} from '../../../text-resources/report-text-resources'

const billingOptions = getObjectAsKeyValueTextArray(dropdownBillingOptions)
const typeOptions = getObjectAsKeyValueTextArray(dropdownOrderTypes)
const billing = 'Laskutus'
const defaultValue = title => title === billing ? 2 : 0

const joinedOptionalValues = checkboxOptionalValues => checkboxOptionalValues.reduce((previous, current) =>
    !previous ? current : `${previous},${current}`, null)

const setValue = (searchField, condition) => {
    const value = condition.title === billing ? condition.value : condition.items[condition.value].text

    if (condition.title === searchField.title)
        return value !== allOptions ? value : null

    return searchField.value
}

const getMultiReportConditionParameter = condition => {
    switch (condition) {
        case 'Kone': return 'ajoneuvo'
        case 'Kuormalaji': return 'kuormalajike'
        case 'Laskutus': return 'laskutettu'
        default: return condition.toLowerCase()
    }
}

const saveConditions = async (item, condition, setConditions, conditions) => {
    const items = condition === billing ? billingOptions : item
    const value = defaultValue(condition)

    setConditions([...conditions, { key: condition, title: condition, items: items, value: value }])
}

const setDropdownContent = (lambdaResult, condition, setConditions, conditions) => {
    const types = [...new Set(lambdaResult)]

    types.unshift(allOptions)
    saveConditions(getObjectAsKeyValueTextArray(types), condition, setConditions, conditions)
}

export const handleFromDateChangeClick = (setFromDate, toDate, setToDate, setJson) => date => {
    setFromDate(date)
    setJson(null)
    date > toDate && setToDate(date)
}

export const handleToDateChangeClick = (setToDate, fromDate, setFromDate, setJson) => date => {
    setToDate(date)
    setJson(null)
    date < fromDate && setFromDate(date)
}

export const handleConditionsClick = ({
    setConditions,
    conditions,
    firstDate,
    secondDate,
    currentTypeIndex,
    currentType,
    handleErrorDispatching,
}) => async condition => {
    setConditions([...conditions, { key: condition, title: condition, items: null, value: 0 }])

    const conditionOption = getMultiReportConditionParameter(condition)

    const body = {
        output: conditionOption,
        from_date: firstDate,
        to_date: secondDate,
        conditions: currentTypeIndex === 0 ? {} : { tyyppi: `${currentType}` },
    }

    try {
        const result = await lambdaCall({
            body: body,
            endpoint: awsApiEndpoints.reportEntryItems,
        })

        const dropdownContent = result.data.map(item => item[conditionOption])

        setDropdownContent(dropdownContent, condition, setConditions, conditions)
    } catch (error) {
        handleErrorDispatching(error)
    }
}

export const removeConditionsClick = (setConditions, conditions) => conditionToBeRemoved => {
    setConditions(conditions.filter(condition => condition.key !== conditionToBeRemoved))
}

export const handleTypeChangeClick = ({
    setCurrentType,
    setCurrentTypeIndex,
    setConditions,
    setCheckedCheckboxes,
    checkedCheckboxes,
    setCheckboxOptionalValues,
    setJson,
}) => (_e, data) => {
    Object.keys(dropdownOrderTypes).forEach((orderType, i) => data.value === i && setCurrentType(dropdownOrderTypes[orderType]))

    typeOptions.forEach((typeOption, index) => {
        if (typeOption.value === data.value)
            setCurrentTypeIndex(index)
    })

    setJson(null)
    setConditions([])
    setCheckedCheckboxes(checkedCheckboxes.fill(0))
    setCheckboxOptionalValues([])
}

export const handleConditionChangeClick = (conditions, setConditions) => (_e, data) => {
    const copyConditions = [...conditions]

    copyConditions.splice(data.index, 1, {
        key: data.title,
        title: data.title,
        items: data.options,
        value: data.value === '' ? defaultValue(data.title) : data.value,
    })

    setConditions(copyConditions)
}

export const handleResetClick = ({
    setFromDate,
    setToDate,
    setCheckedCheckboxes,
    checkedCheckboxes,
    setCheckboxOptionalValues,
    setConditions,
    setCurrentType,
    setCurrentTypeIndex,
    setJson,
}) => () => {
    setFromDate(startOfDateMonthAgo)

    setToDate(endOfCurrentDate)
    setCheckedCheckboxes(checkedCheckboxes.fill(0))
    setCheckboxOptionalValues([])
    setConditions([])
    setCurrentType(dropdownOrderTypes.allOrderTypes)
    setCurrentTypeIndex(0)
    setJson(null)
}

export const dropdownItemIsEmpty = current => nullish(current, dropdownOrderTypes.allOrderTypes)

export const handleCreatePdfClick = (
    searchFields,
    setLoading,
    setJson,
    conditions,
    checkboxOptionalValues,
    {
        loading,
        fromDate,
        toDate,
        currentTypeIndex,
        dispatch,
    }
) => async () => {
    setLoading(true)
    setJson(null)

    if (loading)
        return

    conditions.forEach(condition => {
        searchFields.forEach(searchField => {
            searchField.value = setValue(searchField, condition)
        })
    })

    const startDateString = convertToLocalDate(fromDate)
    const endDateString = convertToLocalDate(toDate)

    const filters = {
        report_type: 'multi_report',
        customer_name: searchFields[3].value,
        worksite_name: searchFields[10].value,
        type_id: currentTypeIndex !== 0 ? currentTypeIndex : null,
        set_dt_strt: startDateString,
        set_dt_end: endDateString,
        billed: searchFields[1].value,
        optional: joinedOptionalValues(checkboxOptionalValues),
        vehicle_name: searchFields[8].value,
        customergroup_name: searchFields[2].value,
        load_name: searchFields[4].value,
        accessory_name: searchFields[0].value,
        pickup_name: searchFields[5].value,
        product_name: searchFields[7].value,
        productgroup_name: searchFields[6].value,
        worker_name: searchFields[9].value ? searchFields[9].value.split(' ').reverse().join(' ') : null,
    }

    try {
        const result = await lambdaCall({ body: filters, endpoint: awsApiEndpoints.reports })
        const jsonParsedResult = JSON.parse(result.data.multi_report[0][0].content)

        jsonParsedResult.content[3].table.body[0][0] ?
            setJson(jsonParsedResult) :
            dispatch(errorAction('Tuloksia ei löytynyt valituilla hakuehdoilla.'))
    } catch {
        dispatch(errorAction())
    }

    setLoading(false)
}

export const getReportEntryItems = async (body, filterTypes, handleErrorDispatching, setPending) => {
    try {
        const res = await lambdaCall({
            body: body,
            endpoint: awsApiEndpoints.reportEntryItems,
        })

        const mappedReportEntryItems = res.data.map(item => item.tyyppi)

        filterTypes(mappedReportEntryItems)
    } catch (error) {
        handleErrorDispatching(error)
        setPending(false)
    }
}
