import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { hasAccess } from '../../utils/route-access'
import PageFooter from '../page-footer/page-footer'
import NavigationBar from '../navigation-bar/navigation-bar'
import PropTypes from 'prop-types'

const RequireAuth = props => {
    const { user } = useSelector(state => state.userData)

    if (user && (user.eula.eulaAccepted || props.userHasAcceptedEula)) {
        return hasAccess(props.path, user.roles) ?
            <div className="container">
                <NavigationBar />
                <div className="grid-main">
                    {props.children}
                </div>
                <PageFooter />
            </div> :
            <Navigate to='/raportit' />
    }

    return <Navigate to='/login' />
}

RequireAuth.defaultProps = { userHasAcceptedEula: false }

RequireAuth.propTypes = {
    children: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    userHasAcceptedEula: PropTypes.bool,
}

export default RequireAuth
