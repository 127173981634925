import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { loginAction } from '../../reducers/login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'
import { errorAction, expiredTokenAction } from '../../reducers/notification-reducer/notification-reducer'

const throwError = (error, dispatch) => {
    if (error.message === 'Token expired') {
        dispatch(loginAction({}, logout))
        dispatch(expiredTokenAction())
    } else {
        dispatch(errorAction())
    }
}

export const handleAcceptEulaClick = (eula, setEulaAccepted, setOpen, dispatch) => async () => {
    const body = { version: eula.currentEulaVersion }

    try {
        const result = await lambdaCall({
            body: body,
            endpoint: awsApiEndpoints.acceptEula,
        })

        if (result.status === 200)
            setEulaAccepted()
    } catch (error) {
        throwError(error, dispatch)
    }

    setOpen(false)
}

export const handleCancelClick = (dispatch, navigate) => () => {
    dispatch(loginAction({}, logout))
    navigate('/')
}

export const handleCheckboxClick = (setChecked, checked) => () => {
    setChecked(!checked)
}
