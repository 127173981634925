import { errorAction, successAction, expiredTokenAction } from '../notification-reducer/notification-reducer'
import { nullish, ifTrueThenValue } from '../../utils/helper-functions'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { loginAction } from '../login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'

// ACTION TYPES
const GET_REQ = 'GET-ENTRY-ITEMS-REQUEST'
const GET_SUCCESS = 'GET-ENTRY-ITEMS-SUCCESS'
const GET_REQ_FULL = 'GET-FULL-ENTRY-ITEMS-REQUEST'
const GET_SUCCESS_FULL = 'GET-FULL-ENTRY-ITEMS-SUCCESS'
const CREATE_ENTRY_ITEM_REQ = 'CREATE-ENTRY-ITEMS-REQUEST'
const CREATE_ENTRY_ITEM_SUCCESS = 'CREATE-ENTRY-ITEMS-SUCCESS'
const EDIT_ENTRY_ITEM_REQ = 'EDIT-ENTRY-ITEMS-REQUEST'
const EDIT_ENTRY_ITEM_SUCCESS = 'EDIT-ENTRY-ITEMS-SUCCESS'
const DELETE_ENTRY_ITEM_REQ = 'DELETE-ENTRY-ITEMS-REQUEST'
const DELETE_ENTRY_ITEM_SUCCESS = 'DELETE-ENTRY-ITEMS-SUCCESS'
const FAILURE = 'ENTRY-ITEMS-FAILURE'
const initialState = { entryItems: undefined, fullEntryItems: undefined }
const duplicateErrorMessage = 'Entry item is duplicate'

const returnListOfEntryItems = (items, action, newItem) => ({
    ...items,
    [action.entryItemType]: [
        ...items[action.entryItemType],
        newItem,
    ],
})

const mapReturnList = (items, action, entryItem) => ({
    ...items,
    [action.entryItemType]: [
        ...items[action.entryItemType].map(item =>
            item.id === action.payload.id ?
                entryItem :
                item),
    ],
})

const handleCreateEntryItemSuccess = (state, action) => {
    let newEntryItems

    if (state.entryItems) {
        const newEntryItem = {
            id: action.payload.id,
            text: action.payload.name,
            ...ifTrueThenValue([!!action.payload.unit_id], { unit_id: action.payload.unit_id }),
            ...ifTrueThenValue([!!action.payload.group_id], { group_id: action.payload.group_id }),
        }

        newEntryItems = returnListOfEntryItems(state.entryItems, action, newEntryItem)
    }

    let newFullEntryItems

    if (state.fullEntryItems) {
        newFullEntryItems = {
            ...state.fullEntryItems,
            [action.entryItemType]: [...state.fullEntryItems[action.entryItemType], action.payload],
        }
    }

    return {
        ...state,
        loading: false,
        entryItems: nullish(newEntryItems, state.entryItems),
        fullEntryItems: nullish(newFullEntryItems, state.fullEntryItems),
    }
}

const handleEditEntryItemSuccess = (state, action) => {
    let editedEntryItems

    if (state.entryItems) {
        const editedEntryItem = {
            id: action.payload.id,
            text: action.payload.name,
            ...ifTrueThenValue([!!action.payload.unit_id], { unit_id: action.payload.unit_id }),
            ...ifTrueThenValue([!!action.payload.group_id], { group_id: action.payload.group_id }),

        }

        editedEntryItems = mapReturnList(state.entryItems, action, editedEntryItem)
    }

    let editedFullEntryItems

    if (state.fullEntryItems)
        editedFullEntryItems = mapReturnList(state.fullEntryItems, action, action.payload)

    return {
        ...state,
        loading: false,
        entryItems: nullish(editedEntryItems, state.entryItems),
        fullEntryItems: nullish(editedFullEntryItems, state.fullEntryItems),
    }
}

const handleDeleteEntryItemSuccess = (state, action) => {
    let entryItemsAfterDelete

    if (state.entryItems) {
        entryItemsAfterDelete = {
            ...state.entryItems,
            [action.entryItemType]: [
                ...state.entryItems[action.entryItemType].filter(item => item.id !== action.payload.id),
            ],
        }
    }

    let fullEntryItemsAfterDelete

    if (state.fullEntryItems) {
        fullEntryItemsAfterDelete = {
            ...state.fullEntryItems,
            [action.entryItemType]: [...state.fullEntryItems[action.entryItemType].filter(item => item.id !== action.payload.id)],
        }
    }

    return {
        ...state,
        loading: false,
        entryItems: nullish(entryItemsAfterDelete, state.entryItems),
        fullEntryItems: nullish(fullEntryItemsAfterDelete, state.fullEntryItems),
    }
}

// REDUCER
export const entryItemsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_REQ:
            return { ...state, loading: true }
        case GET_SUCCESS:
            return { ...state, loading: false, entryItems: action.payload }
        case GET_REQ_FULL:
            return { ...state, loading: true }
        case GET_SUCCESS_FULL:
            return { ...state, loading: false, fullEntryItems: action.payload }
        case FAILURE:
            return { ...state, loading: false }
        case CREATE_ENTRY_ITEM_REQ:
            return { ...state, loading: true }
        case CREATE_ENTRY_ITEM_SUCCESS:
            return handleCreateEntryItemSuccess(state, action)
        case EDIT_ENTRY_ITEM_REQ:
            return { ...state, loading: true }
        case EDIT_ENTRY_ITEM_SUCCESS:
            return handleEditEntryItemSuccess(state, action)
        case DELETE_ENTRY_ITEM_REQ:
            return { ...state, loading: true }
        case DELETE_ENTRY_ITEM_SUCCESS:
            return handleDeleteEntryItemSuccess(state, action)
        default:
            return state
    }
}

// ACTION CREATORS
export const entryItemAction = (body, config) => async dispatch => {
    try {
        if (config.request)
            dispatch({ type: config.request })

        const response = await lambdaCall({ body: body, endpoint: config.endpoint, duplicateErrorMessage: duplicateErrorMessage })

        dispatch({
            type: config.dispatchType,
            payload: response.data,
            status: response.status,
            entryItemType: body.entry_item_type,
        })

        config.successText && dispatch(successAction(config.successText))
    } catch (error) {
        console.error(error)

        if (error.message !== 'Request cancelled') {
            dispatch({ type: FAILURE })
            dispatch(errorAction())
        }

        if (error.message === duplicateErrorMessage)
            dispatch(errorAction(`${body.notificationValue} "${body.name}" on jo olemassa`))

        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        }
    }
}
