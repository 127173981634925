import React, { useState, useEffect, useCallback } from 'react'
import { Button, Loader, Select } from 'semantic-ui-react'
import DateInterval from '../../../components/date-interval/date-interval'
import PdfEmbed from '../../../components/pdf-embed/pdf-embed'
import MultiReportDropdown from './multi-report-dropdown/multi-report-dropdown'
import MultiReportAdditionalInfoFields from './multi-report-additional-info-fields/multi-report-additional-info-fields'
import MultiReportConditions from './multi-report-conditions/multi-report-conditions'
import { getObjectAsKeyValueTextArray } from '../../../utils/helper-functions'
import { multiReportConditionOptions, dropdownOrderTypes } from '../../../text-resources/report-text-resources'
import { errorAction, expiredTokenAction } from '../../../reducers/notification-reducer/notification-reducer'
import { useDispatch } from 'react-redux'
import { loginAction } from '../../../reducers/login-reducer/login-reducer'
import { logout } from '../../../utils/redux-dispatch-configurations'
import { sortFromAndToDates, startOfDateMonthAgo, endOfCurrentDate } from '../../../utils/time'
import {
    handleFromDateChangeClick,
    handleToDateChangeClick,
    handleConditionsClick,
    removeConditionsClick,
    handleTypeChangeClick,
    handleConditionChangeClick,
    handleResetClick,
    dropdownItemIsEmpty,
    handleCreatePdfClick,
    getReportEntryItems,
} from './multi-report.functions'

/*
    Columns
    0=Kaikki tyypit
    1=Kuljetus
    2=Kaivuutyö
    3=Tuntiajo
    4=Tarvike
    5=Henkilötyö
*/

const conditionalMatrix = [
    [1, 1, 1, 1, 1, 1], // asiakas
    [1, 1, 1, 1, 1, 1], // asiakasryhmä
    [1, 1, 1, 1, 0, 0], // kone
    [1, 1, 0, 1, 0, 0], // kuormalaji
    [1, 1, 1, 1, 1, 1], // laskutus
    [1, 0, 1, 1, 0, 0], // lisävaruste
    [1, 1, 0, 0, 0, 0], // noutopaikka
    [1, 0, 0, 0, 1, 0], // tarvike
    [1, 0, 0, 0, 1, 0], // tarvikeryhmä
    [1, 1, 1, 1, 1, 1], // työmaa
    [1, 1, 1, 1, 0, 1], // työntekijä
]

const MultiReport = () => {
    const [conditions, setConditions] = useState([])
    const conditionOptions = multiReportConditionOptions
    const typeOptions = getObjectAsKeyValueTextArray(dropdownOrderTypes)
    const [checkedCheckboxes, setCheckedCheckboxes] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [checkboxOptionalValues, setCheckboxOptionalValues] = useState([])
    const [json, setJson] = useState()
    const [fromDate, setFromDate] = useState(startOfDateMonthAgo)
    const [toDate, setToDate] = useState(endOfCurrentDate)
    const [currentType, setCurrentType] = useState(dropdownOrderTypes.allOrderTypes)
    const [currentTypeIndex, setCurrentTypeIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [filteredTypes, setFilteredTypes] = useState([])
    const [pending, setPending] = useState()
    const dispatch = useDispatch()
    const { firstDate, secondDate } = sortFromAndToDates(fromDate, toDate)

    const searchFields = [
        { title: 'Lisävaruste', value: null },
        { title: 'Laskutus', value: 2 },
        { title: 'Asiakasryhmä', value: null },
        { title: 'Asiakas', value: null },
        { title: 'Kuormalaji', value: null },
        { title: 'Noutopaikka', value: null },
        { title: 'Tarvikeryhmä', value: null },
        { title: 'Tarvike', value: null },
        { title: 'Kone', value: null },
        { title: 'Työntekijä', value: null },
        { title: 'Työmaa', value: null },
    ]

    const filterTypes = useCallback(databaseTypes => {
        const typeOption = getObjectAsKeyValueTextArray(dropdownOrderTypes)

        const filtered = typeOption.map(type => type.text).filter((orderTypes, index) => {
            if (!databaseTypes.includes(orderTypes) && orderTypes !== typeOption[0].text)
                return !!typeOption[index]

            return false
        })

        const options = typeOption.map(option => ({ ...option, disabled: filtered.includes(option.text) }))

        setFilteredTypes(options)
        setPending(false)
    }, [])

    const handleErrorDispatching = useCallback(error => {
        if (error.message === 'Token expired') {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        } else {
            dispatch(errorAction())
        }
    }, [dispatch])

    useEffect(() => {
        setPending(true)

        const body = {
            output: 'tyyppi',
            from_date: sortFromAndToDates(fromDate, toDate).firstDate,
            to_date: sortFromAndToDates(fromDate, toDate).secondDate,
        }

        getReportEntryItems(body, filterTypes, handleErrorDispatching, setPending)
    }, [filterTypes, fromDate, handleErrorDispatching, toDate])

    return (
        <div>
            <div className='reports-description'>
                <h2>Toimintaraportti</h2>
                <div>
                    Toimintaraportti hakee kirjaukset valittujen tietojen perusteella ja tuottaa niistä PDF dokumentin.
                </div>
            </div>
            {pending ?
                <Loader
                    active
                    inline='centered'
                    size='medium' /> :
                <div className='reports-container'>
                    <div className='selector-grid-reports'>
                        <div className='reports-selector-box reports-selector-header'>
                            <h4>Toimituksen aikaväli:</h4>
                        </div>
                        <DateInterval
                            firstDate={fromDate}
                            secondDate={toDate}
                            setFromDate={handleFromDateChangeClick(setFromDate, toDate, setToDate, setJson)}
                            setToDate={handleToDateChangeClick(setToDate, fromDate, setFromDate, setJson)}
                        />
                    </div>

                    <div className='selector-grid-reports'>
                        <div className='reports-selector-box reports-selector-header'>
                            <h4>Tyyppi:</h4>
                        </div>
                        <div className='reports-select'>
                            <Select
                                data-testid='selectType'
                                onChange={handleTypeChangeClick({
                                    setCurrentType,
                                    setCurrentTypeIndex,
                                    setConditions,
                                    setCheckedCheckboxes,
                                    checkedCheckboxes,
                                    setCheckboxOptionalValues,
                                    setJson,
                                })}
                                options={filteredTypes}
                                value={dropdownItemIsEmpty(currentTypeIndex)}
                            />
                        </div>
                    </div>

                    {conditions.map((condition, index) =>
                        <MultiReportDropdown
                            changeOptions={handleConditionChangeClick(conditions, setConditions)}
                            entryItemType={condition.title}
                            index={index}
                            items={condition.items}
                            key={condition.key}
                            remove={removeConditionsClick(setConditions, conditions)}
                        />)}

                    <div className='selector-grid-reports'>
                        <div className='reports-selector-box reports-selector-header'>
                            <h4>Rajausehdot</h4>
                        </div>
                        <div className='reports-add-conditions'>
                            <MultiReportConditions
                                addConditions={handleConditionsClick({
                                    setConditions,
                                    conditions,
                                    firstDate,
                                    secondDate,
                                    currentTypeIndex,
                                    currentType,
                                    handleErrorDispatching,
                                })}
                                conditionOptions={conditionOptions}
                                conditionalMatrix={conditionalMatrix}
                                conditions={conditions}
                                currentTypeIndex={currentTypeIndex}
                            />
                        </div>
                    </div>

                    <MultiReportAdditionalInfoFields
                        checkboxOptionalValues={checkboxOptionalValues}
                        checkedCheckboxes={checkedCheckboxes}
                        currentType={currentType}
                        setCheckboxOptionalValues={setCheckboxOptionalValues}
                        setCheckedCheckboxes={setCheckedCheckboxes}
                        typeOptions={typeOptions}
                    />

                    <div className='selector-box-dl-btn'>
                        <Button
                            className='orange-white-btn'
                            content='Lataa'
                            icon='download'
                            onClick={handleCreatePdfClick(
                                searchFields,
                                setLoading,
                                setJson,
                                conditions,
                                checkboxOptionalValues,
                                {
                                    loading,
                                    fromDate,
                                    toDate,
                                    currentTypeIndex,
                                    dispatch,
                                }
                            )} />
                        <Button
                            className='orange-white-btn'
                            content='Tyhjennä'
                            icon='delete'
                            onClick={handleResetClick({
                                setFromDate,
                                setToDate,
                                setCheckedCheckboxes,
                                checkedCheckboxes,
                                setCheckboxOptionalValues,
                                setConditions,
                                setCurrentType,
                                setCurrentTypeIndex,
                                setJson,
                            })} />
                    </div>

                </div>
            }
            {loading ?
                <Loader
                    active
                    className='content-loader'
                    inline='centered' /> :
                <PdfEmbed json={json} />}
        </div>
    )
}

export default MultiReport
