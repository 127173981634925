import { createUser, deleteUser, editUser } from '../../utils/redux-dispatch-configurations'
import { userAction } from '../../reducers/users-reducer/users-reducer'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { errorAction, successAction } from '../../reducers/notification-reducer/notification-reducer'

const cognitoStatusTranslations = {
    UNCONFIRMED: 'Ei vahvistettu',
    CONFIRMED: 'Vahvistettu',
    EXTERNAL_PROVIDER: 'Ulkoinen tarjoaja',
    ARCHIVED: 'Arkistoitu',
    RESET_REQUIRED: 'Tarvitaan salasanan nollaus',
    FORCE_CHANGE_PASSWORD: 'Väliaikainen salasana käytössä',
}

const filterGroups = (usergroups, checkedGroups) => usergroups.map(g => ({ id: g.id, checked: checkedGroups.includes(g.id) }))

export const handleEditClick = (setEdit, setCheckedGroups, setValues, inputRef, clearFields) => item => {
    clearFields()
    setEdit(item)
    setCheckedGroups(item.usergroups)

    setValues({
        id: item.id,
        cognitoId: item.cognitoId,
        email: item.email,
        firstname: item.firstname,
        lastname: item.lastname,
        phone_number: item.phone_number,
        role: item.role[0] || undefined,
        status: cognitoStatusTranslations[item.status] || 'Tuntematon',
    })

    inputRef.current.focus()
}

export const handleDeleteClick = (dispatch, values, clearFields, setEdit, setCheckedGroups, defaultUsergroupId) => e => {
    e.preventDefault()
    setEdit(false)
    dispatch(userAction({ email: values.email, cognitoId: values.cognitoId }, deleteUser))
    clearFields()
    setCheckedGroups([defaultUsergroupId])
}

export const handleCancelClick = (setEdit, clearFields, setCheckedGroups, defaultUsergroupId) => e => {
    e.preventDefault()
    setEdit(false)
    clearFields()
    setCheckedGroups([defaultUsergroupId])
}

export const handleSaveClick = ({
    values,
    checkedGroups,
    edit,
    setEdit,
    dispatch,
    clearFields,
    setCheckedGroups,
    defaultUsergroupId,
    usergroups,
}) => e => {
    e.preventDefault()

    const saveValues = {
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        phone_number: values.phone_number,
        usergroups: checkedGroups,
        role: values.role,
    }

    if (!edit) {
        dispatch(userAction(saveValues, createUser))
        clearFields()
    } else {
        const newBody = createNewBody(edit, saveValues, values.cognitoId, values, usergroups, checkedGroups)

        const userObjectForReducer = {
            id: values.id,
            cognitoId: values.cognitoId,
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            phone_number: values.phone_number,
            role: values.role,
            usergroups: checkedGroups,
        }

        dispatch(userAction(newBody, editUser, userObjectForReducer))
        setEdit(false)
        clearFields()
    }

    setCheckedGroups([defaultUsergroupId])
}

export const sendLink = (setSendLinkPending, values, dispatch) => async e => {
    e.preventDefault()
    setSendLinkPending(true)

    try {
        await lambdaCall({
            body: { email: values.email },
            endpoint: awsApiEndpoints.sendPasswordChangeLink,
        })

        dispatch(successAction('Sähköposti lähetetty'))
    } catch (_error) {
        dispatch(errorAction('Sähköpostin lähetys epäonnistui'))
    }

    setSendLinkPending(false)
}

const createNewBody = (oldValues, newValues, cognitoId, values, usergroups, checkedGroups) => {
    const userAttributes = []

    let { firstname } = oldValues
    let { lastname } = oldValues

    const groups = filterGroups(usergroups, checkedGroups)
    const reqBody = { cognitoId: cognitoId }

    for (const nv in newValues) {
        if (oldValues[nv] === newValues[nv])
            continue

        switch (nv) {
            case 'email':
                userAttributes.push({ Name: 'email', Value: newValues[nv] })
                break
            case 'firstname':
                firstname = newValues[nv]
                break
            case 'lastname':
                lastname = newValues[nv]
                break
            case 'phone_number':
                userAttributes.push({ Name: 'phone_number', Value: newValues[nv] })
                break
            case 'role':
                reqBody.roleChanges = { role: newValues[nv] }
                break
            default:
                reqBody.groupChanges = { groups: groups }
                break
        }
    }

    if ((firstname || lastname) && (firstname !== oldValues.firstname || lastname !== oldValues.lastname)) {
        const nameObject = { Name: 'name', Value: `${firstname} ${lastname}` }

        userAttributes.push(nameObject)
    }

    if (userAttributes.length > 0) {
        reqBody.cognitoUserChanges = userAttributes

        reqBody.maranetUserChanges = {
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.phone_number,
        }
    }

    return reqBody
}

export const deleteButtonIsDisabled = (edit, user) => user.roles[0] < edit.role || user.email === edit.email

export const handleAddGroupClick = setCheckedGroups => groupToAdd => {
    setCheckedGroups(groups =>
        [...groups, groupToAdd.id])
}

export const handleRemoveGroupClick = setCheckedGroups => groupToRemove => {
    setCheckedGroups(groups =>
        groups.filter(group => group !== groupToRemove.id))
}
