import { getOrders, infiniteScrollOrders } from '../../utils/redux-dispatch-configurations'
import { resetAction, ordersAction } from '../../reducers/orders-reducer/orders-reducer'
import { limit, worklogSchema } from './worklog.schema'
import { setNewTableColumns } from '../../utils/columns'

export const setNewLimitStart = (setLimitStart, limitStart) => difference => setLimitStart(limitStart + difference)

export const handleSelectClick = (
    number,
    {
        setSelected,
        setLimitStart,
        dispatch,
        setIsOrderAssignment,
        setWorkerOrUser,
        setTableColumns,
    }
) => () => {
    const [ordersActionBody, nextRunValueConfig] = worklogSchema[number]

    setSelected(number)
    setLimitStart(0)
    dispatch(resetAction())
    dispatch(ordersAction(ordersActionBody, getOrders))
    setIsOrderAssignment(nextRunValueConfig[0])
    setWorkerOrUser(nextRunValueConfig[1])
    setTableColumns(setNewTableColumns(nextRunValueConfig[2]))
    setLimitStart(limit)
}

export const getMoreOrders = (
    infiniteScrollingIsLoading,
    dispatch,
    limitStart,
    isOrderAssignment,
    workerOrUser,
) => () => {
    if (!infiniteScrollingIsLoading) {
        dispatch(ordersAction({
            limit: limit,
            limit_start: limitStart,
            orderassignment: isOrderAssignment,
            user_action: 'get_all_orderassignments',
            worker_or_user_string: workerOrUser,
        }, infiniteScrollOrders))
    }
}
