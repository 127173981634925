import React, { useEffect, useRef, useState } from 'react'
import { Button, Dimmer, Form, Loader } from 'semantic-ui-react'
import { useForm } from '../../hooks'
import TableWithEdit from '../../components/table-with-edit/table-with-edit'
import { useDispatch, useSelector } from 'react-redux'
import TableWithCheckbox from '../../components/table-with-checkbox/table-with-checkbox'
import { groupAction } from '../../reducers/groups-reducer/groups-reducer'
import { userAction } from '../../reducers/users-reducer/users-reducer'
import { getGroups, getUsers } from '../../utils/redux-dispatch-configurations'
import {
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
    sendLink,
    handleSaveClick,
    deleteButtonIsDisabled,
    handleAddGroupClick,
    handleRemoveGroupClick,
} from './users-menu.functions'
import storage from '../../utils/storage'
import './users-menu.css'

const levels = [
    { text: '1: Aliurakoitsija', value: '1' },
    { text: '2: Normaali käyttäjä', value: '2' },
    { text: '3: Esimies', value: '3' },
    { text: '4: Ylempi esimies', value: '4' },
    { text: '5: Laskutus', value: '5' },
    { text: '6: Pääkäyttäjä', value: '6' },
]

const initialValues = {
    email: '',
    firstname: '',
    lastname: '',
    phone_number: '',
    role: '',
}

const tableColumns = [
    {
        header: 'Tunnus',
        valueName: 'email',
        hideInMobile: false,
    },
]

const resetRequiredText = 'Tarvitaan salasanan nollaus'
const temporaryPasswordInUseText = 'Väliaikainen salasana käytössä'
const unconfirmedText = 'Ei vahvistettu'
const usersString = 'users'

const UsersMenu = () => {
    const dispatch = useDispatch()
    const { loading: groupsAreLoading, usergroups, defaultUsergroupId } = useSelector(state => state.groups)
    const { loading: usersAreLoading, users } = useSelector(state => state.users)
    const { values, formErrors, setValues, handleChange, clearFields } = useForm(initialValues)
    const [checkedGroups, setCheckedGroups] = useState([defaultUsergroupId])
    const [edit, setEdit] = useState(false)
    const inputRef = useRef()
    const [sendLinkPending, setSendLinkPending] = useState(false)
    const user = storage.loadUser()

    const buttonIsDisabled = Object.values(formErrors).some(x => x !== null) ||
        Object.values(values).some(x => x === '')

    useEffect(() => {
        !users && dispatch(userAction({}, getUsers))
        !usergroups && users && dispatch(groupAction({}, getGroups))
        defaultUsergroupId && setCheckedGroups([defaultUsergroupId])
    }, [defaultUsergroupId, dispatch, usergroups, users])

    return (
        <div className='main-content'>
            <h2>Hallinnoi käyttäjiä</h2>
            <Dimmer.Dimmable dimmed={usersAreLoading || groupsAreLoading}>
                <Dimmer
                    active={usersAreLoading || groupsAreLoading}
                    inverted
                >
                    <Loader />
                </Dimmer>
                <div>
                    <div className='add-values-container'>
                        <div
                            className='add-values-new'
                            id='form-input'>
                            {edit ? <h3>Muokkaa käyttäjän tietoja</h3> : <h3>Lisää uusi käyttäjä</h3>}
                            <form>
                                <div className='add-values-form'>
                                    <div className='add-values-input'>
                                        <label>Sähköposti: *</label>
                                        <Form.Input
                                            autoComplete="email"
                                            error={formErrors.email}
                                            input={{ ref: inputRef }}
                                            name='email'
                                            onChange={handleChange}
                                            placeholder='Sähköposti'
                                            required
                                            type='email'
                                            value={values.email}
                                        />
                                    </div>
                                    {edit ?
                                        <>
                                            <div className='add-values-input'>
                                                <label>Tila: </label>
                                                <div>
                                                    <Form.Input
                                                        disabled
                                                        value={values.status}
                                                    />
                                                </div>
                                            </div>
                                            {(values.status === resetRequiredText ||
                                                values.status === unconfirmedText ||
                                                values.status === temporaryPasswordInUseText) &&
                                                <div className='add-values-password-change-notification'>
                                                    Salasanan vaihto tapahtuu erillisen sähköpostiin lähetettävän linkin kautta
                                                    <br />
                                                    <Button
                                                        className='orange-white-btn'
                                                        loading={sendLinkPending}
                                                        onClick={sendLink(setSendLinkPending, values, dispatch)}
                                                    >
                                                        Lähetä sähköposti
                                                    </Button>
                                                </div>}
                                        </> :
                                        null}
                                    <div className='add-values-input'>
                                        <label>Etunimi: *</label>
                                        <Form.Input
                                            autoComplete="given-name"
                                            error={formErrors.firstname}
                                            name='firstname'
                                            onChange={handleChange}
                                            placeholder='Etunimi'
                                            required
                                            type='text'
                                            value={values.firstname}
                                        />
                                    </div>
                                    <div className='add-values-input'>
                                        <label>Sukunimi: *</label>
                                        <Form.Input
                                            autoComplete="family-name"
                                            error={formErrors.lastname}
                                            name='lastname'
                                            onChange={handleChange}
                                            placeholder='Sukunimi'
                                            required
                                            type='text'
                                            value={values.lastname}
                                        />
                                    </div>
                                    <div className='add-values-input'>
                                        <label>Puhelinnumero: *</label>
                                        <Form.Input
                                            autoComplete="tel"
                                            error={formErrors.phone_number}
                                            icon='phone'
                                            name='phone_number'
                                            onChange={handleChange}
                                            placeholder='Puhelinnumero'
                                            required
                                            type='tel'
                                            value={values.phone_number}
                                        />
                                    </div>
                                    <div className='add-values-input'>
                                        <label>Käyttötaso: *</label>
                                        <Form.Select
                                            name='role'
                                            onChange={handleChange}
                                            options={levels}
                                            placeholder='Valitse käyttötaso'
                                            type='select'
                                            value={values.role}
                                        />
                                    </div>
                                </div>
                                <div className='flex'>
                                    {edit ?
                                        <>
                                            <Button
                                                className='orange-white-btn'
                                                onClick={handleCancelClick(
                                                    setEdit,
                                                    clearFields,
                                                    setCheckedGroups,
                                                    defaultUsergroupId
                                                )}>Peruuta</Button>
                                            <Button
                                                className='orange-white-btn'
                                                disabled={deleteButtonIsDisabled(edit, user)}
                                                onClick={handleDeleteClick(
                                                    dispatch,
                                                    values,
                                                    clearFields,
                                                    setEdit,
                                                    setCheckedGroups,
                                                    defaultUsergroupId
                                                )}>
                                                Poista
                                            </Button>
                                            <Button
                                                className='orange-white-btn'
                                                disabled={buttonIsDisabled}
                                                onClick={handleSaveClick({
                                                    values,
                                                    checkedGroups,
                                                    edit,
                                                    setEdit,
                                                    dispatch,
                                                    clearFields,
                                                    setCheckedGroups,
                                                    defaultUsergroupId,
                                                    usergroups,
                                                })}>Tallenna</Button>
                                        </> :
                                        <Button
                                            className='orange-white-btn'
                                            disabled={buttonIsDisabled}
                                            onClick={handleSaveClick({
                                                values,
                                                checkedGroups,
                                                edit,
                                                setEdit,
                                                dispatch,
                                                clearFields,
                                                setCheckedGroups,
                                                defaultUsergroupId,
                                                usergroups,
                                            })}>Lisää uusi</Button>
                                    }
                                </div>
                            </form>
                        </div>
                        <div className='add-values-table userlist'>
                            <TableWithEdit
                                columns={tableColumns}
                                currentPage={usersString}
                                edit={edit}
                                items={users}
                                onEdit={handleEditClick(setEdit, setCheckedGroups, setValues, inputRef, clearFields)}
                            />
                        </div>
                    </div>
                    <TableWithCheckbox
                        add={handleAddGroupClick(setCheckedGroups)}
                        checked={checkedGroups}
                        defaultGroupId={defaultUsergroupId}
                        items={usergroups}
                        remove={handleRemoveGroupClick(setCheckedGroups)}
                        title='Käyttäjäryhmät'
                    />
                </div>
            </Dimmer.Dimmable>
        </div>
    )
}

export default UsersMenu
