const accessoryText = 'Lisävaruste'
const customerAsteriskText = 'Asiakas: *'
const customerGroupAsteriskText = 'Asiakasryhmä: *'
const customerNameText = 'Asiakkaan nimi'
const defaultUnitAsteriskText = 'Oletusyksikkö: *'
const municipalityText = 'Paikkakunta: '
const productGroupText = 'Tarvikeryhmä'
const productGroupAsteriskText = 'Tarvikeryhmä: *'
const selectCustomerGroupText = 'Valitse asiakasryhmä'
const selectDefaultUnitText = 'Valitse oletusyksikkö'
const streetColonText = 'Katuosoite: '
const zipCodeColonText = 'Postinumero: '

export const entryItemSchemas = [
    // ASIAKAS
    {
        id: 1,
        tabTitle: 'Asiakkaat',
        tabName: 'asiakkaat',
        title: 'Lisää uusi asiakas',
        tableItems: 'customers',
        notificationValue: 'Asiakas',
        inputFields: [
            {
                valueName: 'name',
                type: 'text',
                label: customerAsteriskText,
                items: null,
                placeholder: customerNameText,
                required: true,
                dependsOn: null,
            },
            {
                valueName: 'address',
                type: 'text',
                label: streetColonText,
                items: null,
                placeholder: 'Katuosoite',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'postal_code',
                type: 'text',
                label: zipCodeColonText,
                items: null,
                placeholder: 'Postinumero',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'city',
                type: 'text',
                label: municipalityText,
                items: null,
                placeholder: 'Paikkakunta',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'email',
                type: 'text',
                label: 'Sähköposti: ',
                items: null,
                placeholder: 'Sähköposti',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'group_id',
                type: 'select',
                label: customerGroupAsteriskText,
                items: 'customerGroups',
                placeholder: selectCustomerGroupText,
                required: true,
                dependsOn: null,
            },
        ],
        tableColumns: [
            {
                header: customerNameText,
                valueName: 'name',
                hideInMobile: false,
            },
            {
                header: 'Asiakasryhmä',
                valueName: 'group_name',
                hideInMobile: true,
            },
        ],
    },
    // TYÖMAAT
    {
        id: 2,
        tabTitle: 'Työmaa',
        tabName: 'tyomaat',
        title: 'Lisää uusi työmaa',
        tableItems: 'worksites',
        notificationValue: 'Työmaa',
        inputFields: [
            {
                valueName: 'name',
                type: 'text',
                label: 'Työmaa: *',
                items: null,
                placeholder: 'Työmaan nimi',
                required: true,
                dependsOn: null,
            },
            {
                valueName: 'address',
                type: 'text',
                label: streetColonText,
                items: null,
                placeholder: 'Katuosoite',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'postal_code',
                type: 'text',
                label: zipCodeColonText,
                items: null,
                placeholder: 'Postinumero',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'city',
                type: 'text',
                label: municipalityText,
                items: null,
                placeholder: 'Paikkakunta',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'customergroup_id',
                type: 'select',
                label: customerGroupAsteriskText,
                items: 'customerGroups',
                placeholder: selectCustomerGroupText,
                required: true,
                dependsOn: null,
            },
            {
                valueName: 'customer_id',
                type: 'select',
                label: customerAsteriskText,
                items: 'customers',
                placeholder: 'Valitse asiakas',
                required: true,
                dependsOn: 'customergroup_id',
            },
        ],
        tableColumns: [
            {
                header: 'Työmaa',
                valueName: 'name',
                hideInMobile: false,
            },
            {
                header: 'Asiakas',
                valueName: 'customer_name',
                hideInMobile: true,
            },
        ],
    },
    // KONEET
    {
        id: 3,
        tabTitle: 'Koneet',
        tabName: 'koneet',
        title: 'Lisää uusi kone',
        tableItems: 'vehicles',
        notificationValue: 'Kone',
        inputFields: [
            {
                valueName: 'name',
                type: 'text',
                label: 'Kone: *',
                items: null,
                placeholder: 'Koneen nimi',
                required: true,
                dependsOn: null,
            },
        ],
        tableColumns: [
            {
                header: 'Kone',
                valueName: 'name',
                hideInMobile: false,
            },
        ],
    },
    // NOUTOPAIKAT
    {
        id: 4,
        tabTitle: 'Noutopaikat',
        tabName: 'noutopaikat',
        title: 'Lisää uusi noutopaikka',
        tableItems: 'pickups',
        notificationValue: 'Noutopaikka',
        inputFields: [
            {
                valueName: 'name',
                type: 'text',
                label: 'Noutopaikka: *',
                items: null,
                placeholder: 'Noutopaikan nimi',
                required: true,
                dependsOn: null,
            },
            {
                valueName: 'address',
                type: 'text',
                label: streetColonText,
                items: null,
                placeholder: 'Katuosoite',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'postal_code',
                type: 'text',
                label: zipCodeColonText,
                items: null,
                placeholder: 'Postinumero',
                required: false,
                dependsOn: null,
            },
            {
                valueName: 'city',
                type: 'text',
                label: municipalityText,
                items: null,
                placeholder: 'Paikkakunta',
                required: false,
                dependsOn: null,
            },
        ],
        tableColumns: [
            {
                header: 'Noutopaikka',
                valueName: 'name',
                hideInMobile: false,
            },
            {
                header: 'Sijainti',
                valueName: 'city',
                hideInMobile: true,
            },
        ],
    },
    // KUORMALAJIKKEET
    {
        id: 5,
        tabTitle: 'Kuormalajikkeet',
        tabName: 'kuormalajikkeet',
        title: 'Lisää uusi kuormalajike',
        tableItems: 'loads',
        notificationValue: 'Kuormalajike',
        inputFields: [
            {
                valueName: 'name',
                type: 'text',
                label: 'Kuormalajike: *',
                items: null,
                placeholder: 'Kuormalajikkeen nimi',
                required: true,
                dependsOn: null,
            },
            {
                valueName: 'unit_id',
                type: 'select',
                label: defaultUnitAsteriskText,
                items: 'units',
                placeholder: selectDefaultUnitText,
                required: true,
                dependsOn: null,
            },
        ],
        tableColumns: [
            {
                header: 'Työmaa',
                valueName: 'name',
                hideInMobile: false,
            },
            {
                header: 'Yksikkö',
                valueName: 'unit_name',
                hideInMobile: true,
            },
        ],
    },
    // LISÄVARUSTEET
    {
        id: 6,
        tabTitle: 'Lisävarusteet',
        tabName: 'lisavarusteet',
        title: 'Lisää uusi lisävaruste',
        tableItems: 'accessories',
        notificationValue: accessoryText,
        inputFields: [
            {
                valueName: 'name',
                type: 'text',
                label: 'Lisävaruste: *',
                items: null,
                placeholder: 'Lisävarusteen nimi',
                required: true,
                dependsOn: null,
            },
        ],
        tableColumns: [
            {
                header: accessoryText,
                valueName: 'name',
                hideInMobile: false,
            },
        ],
    },
    // TARVIKERYHMÄT
    {
        id: 7,
        tabTitle: 'Tarvikeryhmät',
        tabName: 'tarvikeryhmat',
        title: 'Lisää uusi tarvikeryhmä',
        tableItems: 'productgroups',
        notificationValue: productGroupText,
        inputFields: [
            {
                valueName: 'name',
                type: 'text',
                label: productGroupAsteriskText,
                items: null,
                placeholder: 'Tarvikeryhmän nimi',
                required: true,
                dependsOn: null,
            },
        ],
        tableColumns: [
            {
                header: productGroupText,
                valueName: 'name',
                hideInMobile: false,
            },
        ],
    },
    // TARVIKKEET
    {
        id: 8,
        tabTitle: 'Tarvikkeet',
        tabName: 'tarvikkeet',
        title: 'Lisää uusi tarvike',
        tableItems: 'products',
        notificationValue: 'Tarvike',
        inputFields: [
            {
                valueName: 'name',
                type: 'text',
                label: 'Tarvike: *',
                items: null,
                placeholder: 'Tarvikkeen nimi',
                required: true,
                dependsOn: null,
            },
            {
                valueName: 'group_id',
                type: 'select',
                label: productGroupAsteriskText,
                items: 'productgroups',
                placeholder: 'Valitse tarvikeryhmä',
                required: true,
                dependsOn: null,
            },
            {
                valueName: 'unit_id',
                type: 'select',
                label: defaultUnitAsteriskText,
                items: 'units',
                placeholder: selectDefaultUnitText,
                required: true,
                dependsOn: null,
            },
        ],
        tableColumns: [
            {
                header: 'Tarvike',
                valueName: 'name',
                hideInMobile: false,
            },
            {
                header: 'Yksikkö',
                valueName: 'unit_name',
                hideInMobile: true,
            },
        ],
    },

]
