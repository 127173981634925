import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, Table, Button } from 'semantic-ui-react'
import { dbToLocalDate, dbToLocalDateTime } from '../../utils/time'
import IconButton from '../icon-button/icon-button'
import OrderEntryItemRow from './order-entry-item-row/order-entry-item-row'
import Map from '../map/map'
import { ifTrueThenValue, locationIsDisabled } from '../../utils/helper-functions'
import PropTypes from 'prop-types'
import {
    handleOpenOrderassignmentClick,
    handleReserveOrderassignmentClick,
    handleDeleteRecordClick,
    handleEditAndCopyClick,
    handleJobToOrderClick,
    handleModalToggle,
} from './edit-modal.functions'

const EditModal = props => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.userData)
    const location = props.item.sijainti ? props.item.sijainti.split(', ') : ['0', '0']

    const locationObject = {
        lat: Number(location[0]),
        lng: Number(location[1]),
    }

    const buttonsForEditModal = () => {
        if (user.roles.includes('6') || props.item.user_id === user.id) {
            return (
                <>
                    <IconButton
                        className='modal-btn'
                        icon='edit'
                        onClick={handleEditAndCopyClick(true, navigate, props.item, props.isOrderAssignment)}
                        text='Muokkaa' />
                    <IconButton
                        className='modal-btn'
                        icon='copy'
                        onClick={handleEditAndCopyClick(false, navigate, props.item, props.isOrderAssignment)}
                        text='Kopioi' />
                    <IconButton
                        className='modal-btn'
                        icon='trash alternate outline'
                        onClick={handleDeleteRecordClick(
                            props.currentPage,
                            props.isOrderAssignment,
                            props.item,
                            props.setNewLimitStart,
                            props.reportType,
                            dispatch,
                        )}
                        text='Poista'
                    />
                </>
            )
        }

        return null
    }

    const isJobAndHasAWorker = () => {
        if (props.item.worker_id) {
            return (
                <>
                    <IconButton
                        className='modal-btn'
                        icon='folder open outline'
                        onClick={handleJobToOrderClick(props.turnJobToOrder, props.item, navigate)}
                        text='Avaa' />
                    <IconButton
                        className='modal-btn'
                        icon='share'
                        onClick={handleOpenOrderassignmentClick(
                            props.item,
                            dispatch,
                            navigate,
                        )}
                        text='Vapauta'
                    />
                </>
            )
        }

        return (
            <IconButton
                className='modal-btn'
                icon='plus'
                onClick={handleReserveOrderassignmentClick(
                    props.item,
                    user,
                    dispatch,
                    navigate
                )}
                text='Varaa'
            />
        )
    }

    return (
        <Modal
            dimmer='inverted'
            onClose={handleModalToggle(setOpen, false)}
            onOpen={handleModalToggle(setOpen, true)}
            open={open}
            size='small'
            trigger={props.children}
        >
            <Modal.Content className='modal-header'>
                <div className='modal-buttons'>
                    {props.isJob ? isJobAndHasAWorker() : buttonsForEditModal()}
                </div>
                <IconButton
                    className='modal-btn'
                    icon='close'
                    onClick={handleModalToggle(setOpen, false)}
                    text='Sulje' />
            </Modal.Content>
            <Modal.Content>
                <Table
                    basic='very'
                    fixed>
                    <Table.Body>
                        <OrderEntryItemRow
                            data={props.item.kirjausnro}
                            title='Kirjausnro' />
                        <OrderEntryItemRow
                            data={props.item.tyyppi}
                            title='Tyyppi' />
                        <OrderEntryItemRow
                            data={props.item.asiakas}
                            title='Asiakas' />
                        <OrderEntryItemRow
                            data={props.item.työmaa}
                            title='Työmaa' />
                        <OrderEntryItemRow
                            data={props.item.työntekijä}
                            title='Työntekijä' />
                        <OrderEntryItemRow
                            data={props.item.kirjaaja}
                            title='Kirjaaja' />
                        <OrderEntryItemRow
                            data={props.item.ajoneuvo}
                            title='Ajoneuvo' />
                        <OrderEntryItemRow
                            data={props.item.noutopaikka}
                            title='Noutopaikka' />
                        <OrderEntryItemRow
                            data={props.item.kuormalajike}
                            title='Kuormalajike' />
                        <OrderEntryItemRow
                            data={ifTrueThenValue([props.item.määrä !== null], `${props.item.määrä} ${props.item.yksikkö}`)}
                            title='Määrä'
                        />
                        <OrderEntryItemRow
                            data={
                                ifTrueThenValue(
                                    [props.item.kuljetusmatka !== null],
                                    `${props.item.kuljetusmatka} ${props.item.matkayksikkö}`
                                )}
                            title='Kuljetusmatka'
                        />
                        <OrderEntryItemRow
                            data={props.item.kommentti}
                            title='Kommentti' />
                        <OrderEntryItemRow
                            data={props.item.kesto}
                            title='Kesto' />
                        <OrderEntryItemRow
                            data={props.item.aloitus}
                            title='Aloitus' />
                        <OrderEntryItemRow
                            data={props.item.lopetus}
                            title='Lopetus' />
                        <Table.Row>
                            <Table.Cell collapsing>
                                Sijainti:
                            </Table.Cell>
                            <Table.Cell>
                                <div className='map-btn'>
                                    <Map
                                        location={locationObject}
                                    />
                                    <Button
                                        className='orange-white-btn'
                                        content='Avaa uuteen välilehteen'
                                        data-testid='location'
                                        disabled={locationIsDisabled(locationObject)}
                                        href={`https://www.google.com/maps/preview?q=${location}`}
                                        icon='external alternate'
                                        target='_blank'
                                    />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                        <OrderEntryItemRow
                            data={dbToLocalDateTime(props.item.kirjaushetki)}
                            title='Kirjaushetki' />
                        <OrderEntryItemRow
                            data={dbToLocalDate(props.item.toimituspäivä)}
                            title='Toimituspäivä' />
                    </Table.Body>
                </Table>
            </Modal.Content>

        </Modal>
    )
}

EditModal.defaultProps = {
    children: null,
    isJob: false,
    isOrderAssignment: false,
    item: null,
    reportType: null,
    turnJobToOrder: null,
}

EditModal.propTypes = {
    children: PropTypes.element,
    currentPage: PropTypes.string.isRequired,
    isJob: PropTypes.bool,
    isOrderAssignment: PropTypes.bool,
    item: PropTypes.object,
    reportType: PropTypes.string,
    setNewLimitStart: PropTypes.func.isRequired,
    turnJobToOrder: PropTypes.func,
}

export default EditModal
