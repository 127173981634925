import React from 'react'
import { Loader } from 'semantic-ui-react'
import TableWithModal from '../table-with-modal/table-with-modal'
import EditModal from '../edit-modal/edit-modal'
import PropTypes from 'prop-types'

const reportsString = 'reports'

const OrdersTable = props => {
    const getMoreResults = props.tableWithModalConfig.selectDisplay === 'optionTime' ? props.tableWithModalConfig.getMoreOrders : false
    const reportType = props.tableWithModalConfig.selectDisplay === 'optionAmount' ? 'amount' : 'byDate'

    const ordersLimit = props.tableWithModalConfig.selectDisplay === 'optionAmount' ?
        props.tableWithModalConfig.reportAmount :
        props.tableWithModalConfig.limit

    if (!props.tableIsShown)
        return null

    if (props.reportIsLoading) {
        return <Loader
            active
            className='content-loader'
            inline='centered' />
    }

    return (
        <TableWithModal
            columns={props.tableWithModalConfig.tableColumns}
            getMoreResults={getMoreResults}
            items={props.tableWithModalConfig.tableItems}
            limit={ordersLimit}
            limitForOptionAmount={ordersLimit}
            limitStart={props.tableWithModalConfig.limitStart}
            reportType={reportType}
            setNewLimitStart={props.tableWithModalConfig.setNewLimitStart}
            sortOrder={props.tableWithModalConfig.sortOrder}
        >
            <EditModal
                currentPage={reportsString}
                reportType={reportType}
                setNewLimitStart={props.tableWithModalConfig.setNewLimitStart}
            />
        </TableWithModal>
    )
}

OrdersTable.defaultProps = { reportIsLoading: false }

OrdersTable.propTypes = {
    reportIsLoading: PropTypes.bool,
    tableIsShown: PropTypes.bool.isRequired,
    tableWithModalConfig: PropTypes.object.isRequired,
}

export default OrdersTable
