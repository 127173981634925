import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { errorAction, successAction, expiredTokenAction } from '../notification-reducer/notification-reducer'
import { loginAction } from '../login-reducer/login-reducer'
import { logout } from '../../utils/redux-dispatch-configurations'
import { awsApiEndpoints } from 'src/utils/aws-api-endpoints'

// ACTION TYPES
const GET_REQ = 'GET-DATA-REQUEST'
const GET_SUCCESS = 'GET-DATA-SUCCESS'
const GET_FAILURE = 'GET-DATA-FAILURE'
const DELETE_ITEM = 'DELETE-ITEM'
const GET_MORE_REQ = 'GET-MORE-REQUEST'
const GET_MORE_DATA = 'GET-MORE-DATA'
const RESERVE_OR_OPEN_ORDERASSIGNMENT = 'RESERVE-OR-OPEN-ORDERASSIGNMENT'
const RESET_STATE = 'RESET-STATE'
const initialState = { data: [] }
const tokenExpiredText = 'Token expired'

// REDUCER
export const ordersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_REQ:
            return { ...state, dataIsLoading: true }
        case GET_SUCCESS:
            return { ...state, dataIsLoading: false, data: state.data.concat.apply([], [...state.data, action.payload.results]) }
        case RESET_STATE:
            return initialState
        case GET_FAILURE:
            return { ...state, dataIsLoading: false, infiniteScrollingIsLoading: false, data: state.data }
        case GET_MORE_REQ:
            return { ...state, infiniteScrollingIsLoading: true }
        case GET_MORE_DATA:
            return {
                ...state,
                infiniteScrollingIsLoading: false,
                data: state.data.concat.apply([], [...state.data, action.payload.results]),
            }
        case DELETE_ITEM:
            return {
                ...state,
                data: state.data.filter(item => item.tilausnro !== action.payload.id),
            }
        case RESERVE_OR_OPEN_ORDERASSIGNMENT:
            return {
                ...state,
                data: state.data.filter(item => item.tilausnro !== action.payload.tilausnro),
            }
        default:
            return state
    }
}

// ACTION CREATORS
export const ordersAction = (body, config, successText) => async dispatch => {
    try {
        if (config.reset)
            dispatch({ type: RESET_STATE })

        if (config.request)
            dispatch({ type: config.request })

        const response = await lambdaCall({ body: body, endpoint: config.endpoint, isCancellable: true })

        if (config.dispatchType === DELETE_ITEM && response.data.orderAttachmentKeysAndIds) {
            lambdaCall({
                body: {
                    orderAttachmentIds: response.data.orderAttachmentKeysAndIds.attachmentIds,
                    objects: response.data.orderAttachmentKeysAndIds.objects,
                }, endpoint: awsApiEndpoints.deleteFileAttachments,
            })
        }

        dispatch({
            type: config.dispatchType,
            payload: response.data,
        })

        successText && dispatch(successAction(successText))
    } catch (err) {
        if (err.message === tokenExpiredText) {
            dispatch(loginAction({}, logout))
            dispatch(expiredTokenAction())
        }

        if (err.message !== 'Request cancelled') {
            dispatch({ type: GET_FAILURE })
            dispatch(errorAction())
        }
    }
}

export const resetAction = () => async dispatch => dispatch({ type: RESET_STATE })
