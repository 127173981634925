import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import logoImg from '../../assets/images/logo_444444.png'
import EulaModal from '../eula-modal/eula-modal'
import './eula.css'
import PropTypes from 'prop-types'

const Eula = props =>
    <Dimmer
        active
        inverted>
        {props.isLoading ?
            <EulaModal
                eula={props.user.eula}
                setEulaAccepted={props.setEulaAccepted} /> :
            <>
                <img
                    alt='Maranet logo'
                    className='logo-load'
                    src={logoImg} />
                <Loader
                    data-testid={'loader'}
                />
            </>
        }
    </Dimmer>

Eula.defaultProps = { user: {} }

Eula.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    setEulaAccepted: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default Eula
