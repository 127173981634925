import React from 'react'
import { Image } from 'semantic-ui-react'
import checkboxImage from '../../assets/images/checkbox.png'
import checkboxEmptyImage from '../../assets/images/checkbox_empty.png'
import PropTypes from 'prop-types'

const CheckboxBase = props => {
    const image = props.isChecked ? checkboxImage : checkboxEmptyImage
    const alt = props.isChecked ? 1 : 0

    return (
        <Image
            alt={alt}
            centered
            data-testid='checkboxBase'
            onClick={props.onClickHandler}
            size='mini'
            src={image}
        />
    )
}

CheckboxBase.defaultProps = { isChecked: false }

CheckboxBase.propTypes = {
    isChecked: PropTypes.bool,
    onClickHandler: PropTypes.func.isRequired,
}

export default CheckboxBase
