import { lambdaCall } from '../../../services/aws-lambda-service/aws-lambda-service'
import { awsApiEndpoints } from '../../../utils/aws-api-endpoints'
import { successAction, errorAction } from 'src/reducers/notification-reducer/notification-reducer'
import axios from 'axios'

const getAddImageError = (target, previewFiles) => {
    if (target.files[0]?.size > 52428800)
        return 'Liitetiedosto on liian suuri, sallittu koko on enintään 50 MB'

    if (!target.files[0]?.type.includes('image'))
        return 'Liitetiedostoon voi liittää vain kuvia'

    if (previewFiles.length >= 10)
        return 'Liitetiedostoja voi liittää enintään 10 kpl'

    return null
}

export const handleImageUpload = (
    dispatch,
    setIsLoading,
    selected,
    setObjectUrls,
    throwError,
    previewFiles,
) => async event => {
    const imageError = getAddImageError(event.target, previewFiles)

    if (imageError) {
        dispatch(errorAction(imageError))

        return
    }

    setIsLoading(true)

    try {
        const result = await lambdaCall({ body: { file: event.target.files[0].name }, endpoint: awsApiEndpoints.fileAttachment })

        selected.push(result.data.insertOrderAttachmentToDatabaseResult.order_attachment_id)
        axios.put(result.data.s3Result, event.target.files[0])

        getImageLinks(
            result.data.insertOrderAttachmentToDatabaseResult.order_attachment_id,
            null,
            setObjectUrls,
            throwError,
            setIsLoading,
        )
    } catch (error) {
        throwError(error)
        setIsLoading(false)
    }
}

export const deleteImage = async (id, key, dispatch, throwError, setIsLoading) => {
    setIsLoading(true)

    try {
        await lambdaCall({
            body: { orderAttachmentIds: [id], objects: [{ Key: key }] },
            endpoint: awsApiEndpoints.deleteFileAttachments,
        })

        dispatch(successAction('Kuvan poisto onnistui'))
    } catch (error) {
        throwError(error)
    }

    setIsLoading(false)
}

export const handleFileDelete = ({
    index,
    key,
    setPreviewFiles,
    previewFiles,
    setSelected,
    setIsOpen,
    dispatch,
    throwError,
    setIsLoading,
}) => () => {
    setPreviewFiles([...previewFiles.filter(item => item.id !== index)])
    setSelected(previewFiles.filter(item => item.id !== index).map(file => file.id))
    deleteImage(index, key, dispatch, throwError, setIsLoading)

    if (previewFiles.length === 1)
        setIsOpen(false)
}

export const getImageLinks = async (orderAttachmentId, id, setObjectUrls, throwError, setIsLoading) => {
    try {
        const result = await lambdaCall({
            body: {
                orderAttachmentId: orderAttachmentId,
                orderId: id,
            },
            endpoint: awsApiEndpoints.getFileAttachmentLinks,
        })

        if (result.data.objectUrls)
            setObjectUrls(result.data.objectUrls, id)
    } catch (error) {
        throwError(error)
    }

    setIsLoading(false)
}

export const handleModalToggle = (setIsOpen, bool) => () => setIsOpen(bool)

export const handleInputRefClick = fileInputRef => () => fileInputRef.current.click()
