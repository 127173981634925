import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { Modal, Button } from 'semantic-ui-react'
import Marker from './marker/marker'
import { locationIsDisabled } from '../../utils/helper-functions'
import PropTypes from 'prop-types'
import { toggleModal } from './map.functions'
import './map.css'

const apiKeyObject = { key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }

const Map = props => {
    const [open, setOpen] = useState(false)

    return (
        <Modal
            closeIcon
            dimmer='inverted'
            onClose={toggleModal(setOpen, false)}
            onOpen={toggleModal(setOpen, true)}
            open={open}
            trigger={<Button
                className='orange-white-btn'
                content='Näytä'
                disabled={locationIsDisabled(props.location)}
                icon='map marker alternate'
            />}
        >
            <div className='map-modal-size'>
                <GoogleMapReact
                    bootstrapURLKeys={apiKeyObject}
                    defaultCenter={props.location}
                    defaultZoom={17}
                >
                    <Marker
                        lat={props.location.lat}
                        lng={props.location.lng}
                    />
                </GoogleMapReact>
            </div>
        </Modal>
    )
}

Map.propTypes = { location: PropTypes.object.isRequired }

export default Map
