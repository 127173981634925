import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import IconButton from '../../icon-button/icon-button'
import ListWithSearch from './list-with-search/list-with-search'
import SelectorTextInput from './selector-text-input/selector-text-input'
import SelectorModal from './selector-modal/selector-modal'
import { handleModalToggle, handleClear, handleSelect, handleInputChange, handleDeleteClick } from './selector.functions'
import PropTypes from 'prop-types'

const Selector = props => {
    const [isActive, setIsActive] = useState(false)
    const groupList = props.selected.group_id ? props.groups && props.groups.find(item => item.id === props.selected.group_id) : null
    const [selectedGroup, setSelectedGroup] = useState(groupList)
    const [inputDisabled, setInputDisabled] = useState(!(props.selected && !props.selected.id))
    const [placeholderValueOnDisabledInput, setPlaceholderValueOnDisabledInput] = useState(props.selected.text)
    const isSelected = props.selected && props.selected.text
    const className = isSelected ? 'selector-box selector-selected' : 'selector-box'

    const showDeleteIcon = () => placeholderValueOnDisabledInput && <Icon
        link
        name='delete'
        onClick={() => handleClear(setInputDisabled, setPlaceholderValueOnDisabledInput, props.setSelected, props.name)} />

    let filtered

    if (selectedGroup)
        filtered = props.items.filter(item => item.group_id === selectedGroup.id)

    const returnListWithSearch = () => {
        let listWithSearch

        if (selectedGroup) {
            listWithSearch = <>
                <h4>Valittu {props.title}ryhmä</h4>
                <div className="removable-item hover">
                    {selectedGroup.text}
                    <Icon
                        name="delete"
                        onClick={handleDeleteClick(setSelectedGroup)}
                    />
                </div>
                <ListWithSearch
                    items={filtered}
                    setSelected={handleSelect(
                        setInputDisabled,
                        props.setSelected,
                        props.name,
                        setIsActive,
                        setPlaceholderValueOnDisabledInput
                    )}
                    title={props.title} />
            </>
        } else {
            listWithSearch = <ListWithSearch
                items={props.groups}
                setSelected={setSelectedGroup}
                title={`${props.title}ryhmä`} />
        }

        return <div className='column-flex'>
            <h3>{props.title.toUpperCase()}</h3>
            {props.items && props.groups ?
                listWithSearch :
                <ListWithSearch
                    items={props.items}
                    setSelected={handleSelect(
                        setInputDisabled,
                        props.setSelected,
                        props.name,
                        setIsActive,
                        setPlaceholderValueOnDisabledInput
                    )}
                    title={props.title} />}
            <IconButton
                className='orange-white-btn '
                icon='share'
                isMobile={false}
                onClick={handleModalToggle(setIsActive, isActive)}
                text='Peruuta' />
        </div>
    }

    return (
        <div className={className}>
            {isActive ?
                <SelectorModal
                    isActive={isActive}
                    onClose={handleModalToggle(setIsActive, isActive)}
                    returnListWithSearch={returnListWithSearch()}
                /> :
                <div className='column-flex'>
                    <h3>{props.title.toUpperCase()}</h3>
                    <div className='split-div'>
                        <div className='split-div-first'>
                            <IconButton
                                className='selector-btn'
                                icon="plus"
                                isDisabled={props.isDisabled}
                                onClick={handleModalToggle(setIsActive, isActive)}
                                text="Valitse"
                            />
                        </div>
                        <div className='split-div-second'>
                            <SelectorTextInput
                                isDisabled={inputDisabled}
                                onInputChange={handleInputChange(setPlaceholderValueOnDisabledInput, props.setSelected, props.name)}
                                placeholderValueOnDisabledInput={placeholderValueOnDisabledInput}
                                showDeleteIcon={showDeleteIcon()}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

// Stryker disable all
Selector.defaultProps = {
    groups: null,
    isDisabled: false,
    items: [],
}

Selector.propTypes = {
    groups: PropTypes.array,
    isDisabled: PropTypes.bool,
    items: PropTypes.array,
    name: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    setSelected: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}
// Stryker restore all

export default Selector
