import React, { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import './App.css'
import ReactGA from 'react-ga4'
// COMPONENTS
import RequireAuth from '../components/require-auth/require-auth'
import TopNotification from '../components/top-notification/top-notification'
import OrdersMenu from '../screens/orders-menu/orders-menu'
import EntryItemsMenu from '../screens/entry-items-menu/entry-items-menu'
import ReportsMenu from '../screens/reports-menu/reports-menu'
import Login from '../screens/login/login'
import GroupsMenu from '../screens/groups-menu/groups-menu'
import UsersMenu from '../screens/users-menu/users-menu'
import RetrievePassword from '../screens/retrieve-password/retrieve-password'
import UserRegistration from '../screens/user-registration/user-registration'
import SetPassword from '../screens/set-password/set-password'
import { acceptEula } from './App.functions'

const App = () => {
    const [eulaAccepted, setEulaAccepted] = useState(false)

    ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID)

    return (
        <>
            <TopNotification />
            <Routes>
                <Route
                    element={<Login acceptEula={acceptEula(setEulaAccepted)} />}
                    path='/login' />
                <Route
                    element={<RetrievePassword />}
                    path='/reset' />
                <Route
                    element={<UserRegistration />}
                    path='/register' />
                <Route
                    element={<SetPassword />}
                    path='/set-password/*' />
                <Route
                    element={
                        <RequireAuth
                            path='/kirjaukset'
                            userHasAcceptedEula={eulaAccepted}
                        >
                            <OrdersMenu />
                        </RequireAuth>
                    }
                    path='/kirjaukset/*'
                />
                <Route
                    element={
                        <RequireAuth
                            path='/kirjaustiedot'
                            userHasAcceptedEula={eulaAccepted}
                        >
                            <EntryItemsMenu />
                        </RequireAuth>
                    }
                    path='/kirjaustiedot/*'
                />
                <Route
                    element={
                        <RequireAuth
                            path='/raportit'
                            userHasAcceptedEula={eulaAccepted}
                        >
                            <ReportsMenu />
                        </RequireAuth>
                    }
                    path='/raportit/*'
                />
                <Route
                    element={
                        <RequireAuth
                            path='/kayttajat'
                            userHasAcceptedEula={eulaAccepted}
                        >
                            <UsersMenu />
                        </RequireAuth>
                    }
                    path='/kayttajat'
                />
                <Route
                    element={
                        <RequireAuth
                            path='/ryhmat'
                            userHasAcceptedEula={eulaAccepted}
                        >
                            <GroupsMenu />
                        </RequireAuth>
                    }
                    path='/ryhmat/*'
                />
                <Route
                    element={<Navigate to='/kirjaukset' />}
                    path='/' />
            </Routes>
        </>
    )
}

export default App
