import { ordersAction } from '../../reducers/orders-reducer/orders-reducer'
import { reportAction } from '../../reducers/reports-reducer/reports-reducer'
import { deleteOrder, editOrder } from '../../utils/redux-dispatch-configurations'

const ordersRoute = '/kirjaukset'
const deleteText = 'Poisto onnistui'

const types = {
    1: 'kuljetus',
    2: 'kaivuutyo',
    3: 'tuntiajo',
    4: 'tarvikkeet',
    5: 'henkilotyo',
}

export const handleOpenOrderassignmentClick = (editItem, dispatch, navigate) => () => {
    const openOa = {
        id: editItem.tilausnro,
        worker_id: undefined,
    }

    dispatch(ordersAction({ reserve_or_open: true, ...openOa }, editOrder, 'Työn vapautus onnistui'))
    navigate(ordersRoute)
}

export const handleReserveOrderassignmentClick = (editItem, user, dispatch, navigate) => () => {
    const reservedOa = {
        id: editItem.tilausnro,
        worker_id: user.id,
    }

    dispatch(ordersAction({ reserve_or_open: true, ...reservedOa }, editOrder, 'Työn varaus onnistui'))
    navigate(ordersRoute)
}

export const handleDeleteRecordClick = (currentPage, isOrderAssignment, item, setNewLimitStart, reportType, dispatch) => () => {
    if (currentPage === 'orders') {
        dispatch(ordersAction({ orderassignment: isOrderAssignment, id: item.tilausnro }, deleteOrder, deleteText))
        setNewLimitStart(-1)

        return
    }

    if (currentPage === 'reports') {
        dispatch(reportAction({ orderassignment: false, id: item.tilausnro }, deleteOrder, deleteText))
        reportType === 'byDate' && setNewLimitStart(-1)
    }
}

export const handleEditAndCopyClick = (value, navigate, item, isOrderAssignment) => () => {
    navigate(`/kirjaukset/${types[item.type_id]}`, { state: { edit: value, item: item, oa: isOrderAssignment } })
}

export const handleJobToOrderClick = (turnJobToOrder, item, navigate) => () => {
    turnJobToOrder(true)

    return navigate(`/kirjaukset/${types[item.type_id]}`, { state: { edit: true, item: item } })
}

export const handleModalToggle = (setOpen, bool) => () => setOpen(bool)
