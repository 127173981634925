import axios from 'axios'
import storage from '../../utils/storage'
import { getNewCognitoTokens } from '../cognito-token-service/cognito-token-service'

const tokenExpiredText = 'Token expired'
const loginTime = 'login_time'

let cancelToken

const lambdaCall = async ({ body, endpoint, isCancellable = false, timeoutMs = 22000, duplicateErrorMessage }) => {
    try {
        const currentTime = new Date(Date.now())

        const tokenHasExpired = (currentTime.getTime() -
            new Date(localStorage.getItem(loginTime)).getTime()) /
            1000 -
            storage.loadUser()?.token_expiration_time > 0

        if (storage.loadUser() && tokenHasExpired) {
            localStorage.setItem(loginTime, currentTime)
            await getNewCognitoTokens()
        }

        const token = storage.loadUser()?.id_token
        const headers = { Authorization: token }

        if (cancelToken && isCancellable)
            cancelToken.cancel('Request cancelled')

        cancelToken = axios.CancelToken.source()

        const result = await axios({
            method: endpoint.method,
            url: endpoint.url,
            data: body,
            headers: headers,
            cancelToken: cancelToken.token,
            timeout: timeoutMs,
        })

        if (result.data.isDuplicate)
            throw new Error(duplicateErrorMessage)

        if (result.data.statusCode === 400)
            throw new Error('Invalid parameter')

        return result
    } catch (error) {
        if (error.message === tokenExpiredText)
            throw new Error(tokenExpiredText)

        throw error
    }
}

export { lambdaCall }
