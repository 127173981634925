import { loginAction } from '../../reducers/login-reducer/login-reducer'
import { lambdaCall } from '../../services/aws-lambda-service/aws-lambda-service'
import { getStorageInfoJson } from '../../utils/helper-functions'
import { awsApiEndpoints } from '../../utils/aws-api-endpoints'
import { logout } from '../../utils/redux-dispatch-configurations'

export const setActiveTab = (setSelected, setNavActive, match) => {
    setSelected(match.params.selection)
    setNavActive(false)
}

export const handleNavOpenState = (setNavActive, navActive) => () => setNavActive(!navActive)

export const handleLogoutClick = dispatch => () => {
    const body = {
        email: getStorageInfoJson('email'),
        refreshToken: getStorageInfoJson('refresh_token'),
    }

    lambdaCall({ body: body, endpoint: awsApiEndpoints.revokeRefreshToken })
    dispatch(loginAction({}, logout))
}

export const handleNavigate = navigate => () => navigate('/kirjaukset')
