export const handleInputChange = (setComment, name, setSelected) => e => {
    if (e.target.value) {
        const targetValueWithLengthLimit = e.target.value.slice(0, 10_000)

        setComment(targetValueWithLengthLimit)
        setSelected({ text: targetValueWithLengthLimit }, name)
    } else {
        setComment('')
        setSelected(null, name)
    }
}
